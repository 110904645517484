import classNames from "classnames";
import { Link } from "react-router-dom";
import { Props } from "./interface";

export const NavBanner = ({
  parent,
  children,
  className,
  callback,
}: Props): JSX.Element => {
  const generatedClasses: Record<string, string> = {
    menu: classNames(className, "c-mega-menu"),
    wrapper: classNames("c-mega-menu_wrapper"),
    main: classNames("c-mega-menu_main"),
    heading: classNames("c-mega-menu_heading"),
    headingLink: classNames("c-mega-menu_heading-link"),
    list: classNames("c-mega-menu_list"),
    item: classNames("c-mega-menu_item"),
    link: classNames("c-mega-menu_link"),
  };

  return (
    <div className={generatedClasses.menu}>
      <div className={generatedClasses.wrapper}>
        <div className={generatedClasses.main}>
          <div className={generatedClasses.heading}>
            <Link
              className={generatedClasses.headingLink}
              to={parent.href}
              onClick={callback}
            >
              {parent.title}
            </Link>
          </div>
          {children?.length && (
            <ul className={generatedClasses.list}>
              {children?.map(({ title, href }, index) => (
                <li className={generatedClasses.item} key={index}>
                  <Link
                    className={generatedClasses.link}
                    to={href}
                    onClick={callback}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
