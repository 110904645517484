import { Props } from "./interfaces";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export const Checkbox = ({
  id,
  input,
  meta,
  placeholder = "Enter text",
  isLabelHidden,
  label,
  setFieldRef,
  classes,
}: Props): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    setFieldRef(id, ref);
  }, []);

  const generatedClasses = {
    wrapper: classNames("u-flex", classes?.wrapper),
    label: classNames("c-label tablet:u-mr-4", classes?.label, {
      ["u-sr-only"]: isLabelHidden,
    }),
    input: classNames(
      "c-input-text u-inline-block u-font-family-bold u-w-6 u-h-6"
    ),
  };

  return (
    <div className={generatedClasses.wrapper}>
      <label htmlFor={id} className={generatedClasses.label}>
        {label}
      </label>
      <input
        placeholder={placeholder}
        {...input}
        id={id}
        ref={ref}
        type="checkbox"
        className={generatedClasses.input}
      />
    </div>
  );
};
