import { messageConstants } from "../../constants";
import { isDefined } from "../../helpers/utils";
import { isValid, parse, parseISO, isBefore, isAfter } from "date-fns";
import { FieldValidatorConfig } from "../validator";

export interface DateValidator extends FieldValidatorConfig {
  datePriorTo: string;
  dateAfter: string;
  message: string;
  datePriorToMessage: string;
  dateAfterMessage: string;
  pastOnlyMessage: string;
  futureOnlyMessage: string;
  isPastOnly: boolean;
  isFutureOnly: boolean;
}

export const dateValidator = (
  value: any,
  formValues: Record<string, string>,
  validatorConfig: any
) => {
  const datePriorTo: string = validatorConfig.datePriorTo;
  const dateAfter: string = validatorConfig.dateAfter;
  const message: string = validatorConfig.message;
  const datePriorToMessage: string = validatorConfig.datePriorToMessage;
  const dateAfterMessage: string = validatorConfig.dateAfterMessage;
  const pastOnlyMessage: string = validatorConfig.pastOnlyMessage;
  const futureOnlyMessage: string = validatorConfig.futureOnlyMessage;
  const isPastOnly: boolean = validatorConfig.isPastOnly;
  const isFutureOnly: boolean = validatorConfig.isFutureOnly;
  const hasDatePriorTo: boolean = Boolean(datePriorTo);
  const hasDateAfter: boolean = Boolean(dateAfter);

  if (
    !isDefined(message) ||
    (hasDatePriorTo && !isDefined(datePriorToMessage)) ||
    (isPastOnly && !isDefined(pastOnlyMessage)) ||
    (hasDateAfter && !isDefined(dateAfterMessage)) ||
    (isFutureOnly && !isDefined(futureOnlyMessage))
  ) {
    throw new Error(messageConstants.validatorMisconfigured);
  }

  if (!value || typeof value !== "string") {
    return undefined;
  }

  const valueAsDate: any = parseISO(value);
  const isDate: boolean = isValid(valueAsDate);

  if (!isDate) {
    return message;
  }

  if (hasDatePriorTo) {
    return valueAsDate < parseISO(datePriorTo) ? undefined : datePriorToMessage;
  }

  if (isPastOnly) {
    return isBefore(valueAsDate, new Date()) ? undefined : pastOnlyMessage;
  }

  if (hasDateAfter) {
    return valueAsDate > parseISO(dateAfter) ? undefined : dateAfterMessage;
  }

  if (isFutureOnly) {
    return isBefore(valueAsDate, new Date()) ? undefined : futureOnlyMessage;
  }

  return undefined;
};
