import { createContext } from "react";
import { Roles } from "./user";

/**
 * NOTE: Please make sure this id string matches the id provided in /public/index.html
 */
export const rootElementId: string = "insight-user-admin-app";

export type AppContextType = {
  userRoles: Roles;
};

export const AppContext = createContext<AppContextType>({
  userRoles: Roles.None,
});
