import { useState } from "react";
import { Link } from "react-router-dom";
import { mediaQueries } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { Nav } from "../nav";
import { Props } from "./interface";
import classNames from "classnames";

export const Header = ({
  logo,
  text: { skipText, title, menuButton },
  navItems,
}: Props): JSX.Element => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const isTablet: boolean = useMediaQuery({ query: mediaQueries.TABLET });

  const { content: skipToContent, menu: skipToMenu } = skipText ?? {};

  const generatedClasses: Record<string, string> = {
    header: classNames("c-header"),
    wrapper: classNames("c-header_wrapper l-grid-container"),
    srOnly: classNames("u-sr-only"),
    skipSite: classNames("c-skip-site"),
    skipSiteItem: classNames("c-skip-site_item"),
    skipSiteLink: classNames("c-skip-site_link"),
    logo: classNames("c-logo"),
    logoWrapper: classNames("c-logo_wrapper"),
    navToggleWrapper: classNames("c-nav-toggle tablet:u-hidden"),
    navToggleBtn: classNames("c-nav-toggle_btn", {
      ["open"]: !isTablet && isNavOpen,
    }),
    nav: classNames({
      ["u-sr-only"]: !isTablet && !isNavOpen,
    }),
  };

  const handleClick = (e: React.MouseEvent): void => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className={generatedClasses.header}>
      <div className={generatedClasses.wrapper}>
        <h1 className={generatedClasses.srOnly}>{title}</h1>
        <div className={generatedClasses.skipSite}>
          <p className={generatedClasses.skipSiteItem}>
            <a href="#main-content" className={generatedClasses.skipSiteLink}>
              {skipToContent}
            </a>
          </p>
          <p className={generatedClasses.skipSiteItem}>
            <a href="#main-menu" className={generatedClasses.skipSiteLink}>
              {skipToMenu}
            </a>
          </p>
        </div>
        <div className={generatedClasses.logo}>
          <div className={generatedClasses.logoWrapper}>
            <Link to={logo.href} title={logo.title}>
              <img src={logo.src} alt={logo.alt} />
            </Link>
            <div className={generatedClasses.navToggleWrapper}>
              <button
                className={generatedClasses.navToggleBtn}
                onClick={handleClick}
              >
                <span>{menuButton}</span>
              </button>
            </div>
          </div>
        </div>
        <Nav
          className={generatedClasses.nav}
          items={navItems}
          closeNav={() => setIsNavOpen(false)}
        />
      </div>
    </header>
  );
};
