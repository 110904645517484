import { routeApis } from "../../constants/apis";
import { RequestMethods } from "../../constants/fetch";
import { Routes } from "../../constants/routes";
import { activateBasicFetch, setFetchOpts } from "../../helpers/fetch";
import { getPageData } from "../templates/page";

export const pendingRequestsLoader = async (props: any) => {
  return await getPageData({
    url: routeApis[Routes.PENDING_REQUESTS].getPendingItems,
    ...props,
  });
};

export const resubmitPendingRequest = async (url: string, data: any) => {
  const { azureUserId, portal, email, firstName, surname } = data;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: {
      azureUserId,
      portal,
      email,
      firstname: firstName,
      surname,
    },
  });

  return await activateBasicFetch(url, fetchOptions);
};

export const rejectPendingRequest = async (url: string, data: any) => {
  const { azureUserId, portal, email } = data;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: {
      azureUserId,
      portal,
      email,
    },
  });

  return await activateBasicFetch(url, fetchOptions);
};
