import { Outlet } from "react-router-dom";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { Props } from "./interfaces";
import { routePaths, Routes } from "../../constants/routes";
import insightLogo1 from "../../img/insight-logo-103x79.png";
import insightLogo2 from "../../img/footer-insight-logo-lockup.png";
import bnyLogo from "../../img/bny-logo-1.png";
import { useContext } from "react";
import { generateNavConfig } from "../../helpers/config";
import { AppContext, AppContextType } from "../../constants/context";
import { NavItemProps } from "../../components/nav-item/interface";

export const LayoutContainer = ({}: Props): JSX.Element => {
  const appContext = useContext<AppContextType>(AppContext);
  const { userRoles } = appContext;

  const navItems: Array<NavItemProps> = generateNavConfig(userRoles);

  return (
    <>
      <div className="u-relative">
        <Header
          logo={{
            href: routePaths[Routes.HOME],
            src: insightLogo1,
            alt: "Insight logo",
            title: "home page",
          }}
          text={{
            title: "Insight Investment",
            menuButton: "Menu",
            skipText: {
              content: "Skip to main content",
              menu: "Skip to main navigation",
            },
          }}
          navItems={navItems}
        />
      </div>
      <main className="u-overflow-x-hidden u-relative">
        <div id="main-content">
          <div className="l-grid-container">
            <div className="l-row">
              <div className="l-col-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer
          primaryLogo={{
            href: routePaths[Routes.HOME],
            src: insightLogo2,
            alt: "Insight Investment logo",
          }}
          secondaryLogo={{
            href: "https://www.bnymellon.com/emea/en/home.jsp",
            src: bnyLogo,
            alt: "BNYM Investment logo",
          }}
        />
      </div>
    </>
  );
};
