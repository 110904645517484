import { FormConfig } from "../../types/form";
import { Roles } from "../../constants/user";
import { queryBannerFields } from "../fields";

export const pendingPagesForm = (userRoles: Roles): FormConfig => ({
  fields: [
    queryBannerFields.emailAddress,
    queryBannerFields.fromDate,
    queryBannerFields.toDate,
  ],
});
