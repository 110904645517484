import { messageConstants } from "../../constants";
import { isDefined } from "../../helpers/utils";
import { FieldValidatorConfig } from "../validator";

export interface EmailValidator extends FieldValidatorConfig {}

export const emailValidator = (
  value: any,
  formValues: Record<string, string>,
  validatorConfig: any
) => {
  const { message } = validatorConfig;

  if (!isDefined(message)) {
    throw new Error(messageConstants.validatorMisconfigured);
  }

  if (value) {
    const regex = new RegExp(
      "[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?.)+[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?",
      "g"
    );

    if (!value.match(regex)) {
      return message;
    }

    return undefined;
  }

  return undefined;
};
