import { dataKeys } from "./tableHeaders";

interface ColumnConfig {
  hiddenColumns: Array<string>;
  sortable: Array<string>;
  order: Array<string>;
  hiddenFields: Array<string>;
}

export const pendingRequestColumnConfig: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.registrationRejectionReason,
    dataKeys.phoneNumber,
    dataKeys.displayName,
    dataKeys.azureUserId,
    dataKeys.portal,
  ],
  sortable: [
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.lastSubmissionDate,
  ],
  order: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.phoneNumber,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.lastSubmissionDate,
    dataKeys.displayName,
    dataKeys.registrationRejectionReason,
    dataKeys.portal,
  ],
  hiddenFields: [dataKeys.id],
};

export const pendingRegistrationsColumnOrder: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.registrationRejectionReason,
    dataKeys.phoneNumber,
    dataKeys.displayName,
    dataKeys.date,
    dataKeys.portal,
  ],
  sortable: [
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.lastSubmissionDate,
  ],
  order: [
    dataKeys.azureUserId,
    dataKeys.id,
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.phoneNumber,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.lastSubmissionDate,
    dataKeys.displayName,
    dataKeys.registrationRejectionReason,
    dataKeys.portal,
  ],
  hiddenFields: [dataKeys.id],
};

export const pendingEmailChangesColumnOrder: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.registrationRejectionReason,
    dataKeys.phoneNumber,
    dataKeys.displayName,
  ],
  sortable: [
    dataKeys.oldEmailAddress,
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.requestedEmailChangeDateTime,
  ],
  order: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.oldEmailAddress,
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.phoneNumber,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.requestedEmailChangeDateTime,
    dataKeys.registrationRejectionReason,
  ],
  hiddenFields: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.date,
    dataKeys.registrationRejectionReason,
  ],
};

export const clientPortalPageRestrictions: ColumnConfig = {
  hiddenColumns: [dataKeys.id, dataKeys.hasReadWriteOption],
  sortable: [],
  order: [
    dataKeys.id,
    dataKeys.pageName,
    dataKeys.restrictionId,
    dataKeys.hasReadWriteOption,
  ],
  hiddenFields: [dataKeys.id],
};

export const clientPortalSalesforceCampaigns: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.adminEmailAddress,
    dataKeys.registrationApprovalEmailSubject,
    dataKeys.registrationApprovalEmailBody,
    dataKeys.requestApprovalEmailSubject,
    dataKeys.requestApprovalEmailBody,
    dataKeys.portalHubPageCmsId,
    dataKeys.allowHotlinks,
  ],
  sortable: [],
  order: [
    dataKeys.id,
    dataKeys.campaignName,
    dataKeys.salesforceCampaignId,
    dataKeys.adminEmailAddress,
    dataKeys.registrationApprovalEmailSubject,
    dataKeys.registrationApprovalEmailBody,
    dataKeys.requestApprovalEmailSubject,
    dataKeys.requestApprovalEmailBody,
    dataKeys.portalHubPageCmsId,
    dataKeys.allowHotlinks,
  ],
  hiddenFields: [dataKeys.id],
};

export const emailLog: ColumnConfig = {
  hiddenColumns: [dataKeys.id, dataKeys.emailBody, dataKeys.subject],
  sortable: [dataKeys.emailAddress, dataKeys.dateTimeSent, dataKeys.emailType],
  order: [
    dataKeys.id,
    dataKeys.emailAddress,
    dataKeys.dateTimeSent,
    dataKeys.emailType,
    dataKeys.emailBody,
    dataKeys.subject,
  ],
  hiddenFields: [dataKeys.id],
};

export const portalUsers: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.contentId,
    dataKeys.isExUserType,
    dataKeys.cpdApplicable,
    dataKeys.insightInvestmentContact,
    dataKeys.phoneNumber,
    dataKeys.displayName,
    dataKeys.date,
    dataKeys.registrationRejectionReason,
    dataKeys.note,
    dataKeys.authorEmailAddress,
    dataKeys.dateTime,
    dataKeys.authorFirstName,
    dataKeys.authorSurname,
    dataKeys.portal,
  ],
  sortable: [
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.company,
    dataKeys.status,
  ],
  order: [
    dataKeys.azureUserId,
    dataKeys.email,
    dataKeys.firstName,
    dataKeys.surname,
    dataKeys.phoneNumber,
    dataKeys.company,
    dataKeys.insightInvestmentContact,
    dataKeys.displayName,
    dataKeys.date,
    dataKeys.registrationRejectionReason,
    dataKeys.status,
    dataKeys.note,
    dataKeys.authorEmailAddress,
    dataKeys.dateTime,
    dataKeys.portal,
  ],
  hiddenFields: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.authorUserId,
    dataKeys.authorFirstName,
    dataKeys.authorSurname,
  ],
};

export const reporting: ColumnConfig = {
  hiddenColumns: [
    dataKeys.id,
    dataKeys.azureUserId,
    dataKeys.registrationRejectionReason,
  ],
  sortable: [dataKeys.auditLogDateTime, dataKeys.email],
  order: [
    dataKeys.azureUserId,
    dataKeys.date,
    dataKeys.auditLogDateTime,
    dataKeys.time,
    dataKeys.employeeSurname,
    dataKeys.employeeFirstName,
    dataKeys.userSurname,
    dataKeys.userFirstName,
    dataKeys.contactId,
    dataKeys.userSalesforceContactId,
    dataKeys.pageName,
    dataKeys.viewedPageName,
    dataKeys.selectedAccountPortfolioName,
    dataKeys.selectedClient,
    dataKeys.sharePointAgreementFolder,
    dataKeys.activity,
    dataKeys.accountPortfolio,
    dataKeys.client,
    dataKeys.documentFolder,
    dataKeys.documentsPageFolder,
    dataKeys.documentName,
    dataKeys.instructionReference,
    dataKeys.fileDownloaded,
  ],
  hiddenFields: [dataKeys.id, dataKeys.azureUserId],
};

export const columnConfig = {
  pendingRequestColumnConfig,
  pendingRegistrationsColumnOrder,
  pendingEmailChangesColumnOrder,
  clientPortalPageRestrictions,
  clientPortalSalesforceCampaigns,
  portalUsers,
  emailLog,
  reporting,
};
