import { routeApis } from "../../constants/apis";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const emailLogLoader = async (props: any) => {
  return getPageData({
    url: routeApis[Routes.EMAIL_LOG].getEmailLog,
    ...props,
  });
};
