import { format } from "date-fns";
import { DEFAULT_DATE_INPUT_FORMAT } from "../../constants";
import { FormConfig } from "../../types/form";
import { queryBannerFields } from "../fields";

const today: string = format(Date.now(), DEFAULT_DATE_INPUT_FORMAT);

export const reportingFormConfig: FormConfig = {
  fields: [
    { ...queryBannerFields.fromDate, initialValue: today },
    { ...queryBannerFields.toDate, initialValue: today },
  ],
};
