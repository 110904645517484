import classNames from "classnames";
import { useEffect, useState } from "react";
import { portalUserStatuses } from "../../../constants";
import { routeApis } from "../../../constants/apis";
import { RequestMethods } from "../../../constants/fetch";
import { Routes } from "../../../constants/routes";
import {
  dataKeys,
  portalUsersTableHeaders,
} from "../../../constants/tableHeaders";
import {
  ClientPortalPageRestrictionPermissions,
  Portal,
} from "../../../constants/user";
import { FormContainer } from "../../../containers/form";
import { fetchWithTimeOut, setFetchOpts } from "../../../helpers/fetch";
import { isDefined } from "../../../helpers/utils";
import { FieldRenderers, FormConfig } from "../../../types/form";
import { PageRestriction, UserPageRestriction } from "../../../types/global";
import { ActionButton } from "../../actionButton";
import { ErrorSummary } from "../../forms/errorSummary";
import { Loading } from "../../loading";
import { Props } from "./interface";
import { format } from "date-fns";

export const Submitting = ({ submitting }: { submitting?: boolean }) =>
  submitting ? <div>Submitting...</div> : null;

export const PortalUsersInnerTemplateDialog = ({
  data,
  title,
  actions,
  onFormSubmit,
  loadingText = "Loading content...",
  hiddenFields,
  changeRequestNotes = [],
  onReactivate,
  reactivateError,
}: Props): JSX.Element => {
  const { azureUserId, portal, status } = data ?? {};

  const { ...item } = data ?? {};

  hiddenFields?.forEach((hiddenField: string) => {
    if (item.hasOwnProperty(hiddenField)) {
      delete item[hiddenField];
    }
  });

  const isActiveClientPortalUser =
    portal === Portal.ClientPortal &&
    (status === portalUserStatuses.Active ||
      status === portalUserStatuses.ResubmittedActive);

  const shouldEnableReactivating =
    (status === portalUserStatuses.Rejected ||
      status === portalUserStatuses.Removed ||
      status === portalUserStatuses.ResubmittedRejected ||
      status === portalUserStatuses.ResubmittedRemoved) &&
    portal === Portal.ClientPortal;

  // transform action button content if current user in table is not client portal user.
  if (!isActiveClientPortalUser && actions?.length) {
    actions?.pop();

    actions[0].text = "Close";
  }

  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [pageRestrictions, setPageRestrictions] =
    useState<Array<UserPageRestriction> | null>();
  const shouldRender: boolean =
    (Boolean(formConfig) && Boolean(pageRestrictions)) ||
    !isActiveClientPortalUser;

  const approvedEmailChanges: Array<Record<string, any>> =
    changeRequestNotes?.filter(
      (note) => note.userChangeStatus === "Approved"
    ) ?? [];
  const rejectedEmailChanges: Array<Record<string, any>> =
    changeRequestNotes?.filter(
      (note) => note.userChangeStatus === "Rejected"
    ) ?? [];

  useEffect(() => {
    if (isDefined(azureUserId) && isActiveClientPortalUser)
      generateFormConfig(azureUserId);
  }, [azureUserId]);

  const generateFormConfig = async (id: string) => {
    const fetchOptions = setFetchOpts({
      method: RequestMethods.GET,
    });

    try {
      const url: string = `${
        routeApis[Routes.PORTAL_USERS].userPageRestrictions
      }?userId=${id}`;

      const response = await Promise.all([
        fetchWithTimeOut(url, fetchOptions, 10000),
        fetchWithTimeOut(
          routeApis[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS].getPageRestrictions,
          fetchOptions,
          10000
        ),
      ]);

      if (response[0].ok && response[1].ok) {
        const pageRestrictions: Array<PageRestriction> =
          await response[1].json();
        const userPageRestrictions: Array<UserPageRestriction> =
          await response[0].json();

        setPageRestrictions(userPageRestrictions);

        const generatedFormConfig: FormConfig = {
          fields: pageRestrictions.map((pageRestriction: PageRestriction) => {
            const userRestriction: UserPageRestriction | undefined =
              userPageRestrictions.find(
                (item: UserPageRestriction) =>
                  item.pageRestrictionId === pageRestriction.restrictionId
              );

            return {
              // id in final form cannot be numeric, must contain alpha characters.
              id: `restriction:${pageRestriction.restrictionId}`,
              validators: [],
              label: pageRestriction.pageName,
              isLabelHidden: false,
              initialValue:
                userRestriction?.restrictionPermission ||
                ClientPortalPageRestrictionPermissions.NoAccess,
              renderer: FieldRenderers.SELECT,
              options: [
                {
                  value: ClientPortalPageRestrictionPermissions.NoAccess,
                  displayText: "No Access",
                },
                {
                  value: ClientPortalPageRestrictionPermissions.ReadOnly,
                  displayText: "Read Only",
                },
                ...(pageRestriction.hasReadWriteOption
                  ? [
                      {
                        value: ClientPortalPageRestrictionPermissions.ReadWrite,
                        displayText: "Read/Write",
                      },
                    ]
                  : []),
              ],
              classes: {
                label: "o-form-label u-text-sm",
                wrapper: "u-flex u-flex-row-reverse u-justify-end u-gap-2",
              },
            };
          }),
        };

        setFormConfig(generatedFormConfig);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generatedClasses: Record<string, string> = {
    buttonWrapper: classNames("u-flex u-justify-end u-mb-6"),
    button: classNames(
      "u-bg-teal-green u-text-white u-border-none u-p-0 u-rounded-xl u-my-2 u-flex u-items-center"
    ),
    svgButton: classNames(
      "u-bg-transparent u-border-none u-p-0 u-m-0 u-text-fiord-blue u-py-2 u-flex u-items-center"
    ),
    item: classNames(
      "u-flex u-flex-col u-items-left u-m-0 u-border-0 u-border-b u-border-solid u-border-white"
    ),
    dlItem: classNames("u-flex u-gap-2 u-flex-wrap u-my-2"),
    itemTitle: classNames("u-font-bold"),
    itemText: classNames("u-p-0 u-m-0"),

    formWrapper: classNames("c-form u-my-4"),
    fieldWrapper: classNames(
      "o-form-item u-flex u-flex-col u-flex-wrap u-gap-2 u-ml-4"
    ),
    actionButtonWrapper: classNames("u-flex u-mt-4 u-gap-2 u-flex-wrap", {
      ["u-justify-between"]: isActiveClientPortalUser,
      ["u-justify-center"]: !isActiveClientPortalUser,
    }),
  };

  return shouldRender ? (
    <div className="u-p-3 u-pb-0">
      <h2 className="u-p-0 u-m-0">{title}</h2>
      <div className="u-my-2">
        <dl className={generatedClasses.item}>
          {Object.keys(item).map((key, index) => (
            <div className={generatedClasses.dlItem} key={index}>
              <dt className={generatedClasses.itemTitle}>
                {portalUsersTableHeaders[key] ?? key}:
              </dt>
              <dd className={generatedClasses.itemText}>{`${
                data[key] ?? ""
              }`}</dd>
            </div>
          ))}
        </dl>
        {shouldEnableReactivating && onReactivate && (
          <div>
            <ActionButton
              className="u-rounded-none u-w-fit"
              callback={onReactivate}
            >
              Reactivate B2C account
            </ActionButton>
            <div className="u-mt-2">
              <ErrorSummary globalError={reactivateError}></ErrorSummary>
            </div>
          </div>
        )}
        {(approvedEmailChanges?.length > 0 ||
          rejectedEmailChanges?.length > 0) && (
          <div className="u-bg-mercury-grey u-py-2 u-my-2 u-px-1 u-border-y-2 u-border-x-0 u-border-solid u-border-y-edward-grey">
            <h3 className="u-m-0">Email change request</h3>
            {approvedEmailChanges?.length > 0 && (
              <div className="u-ml-4 u-mb-4">
                <h4 className="u-m-0 u-my-2 u-font-bold">Approval(s)</h4>
                <div className="u-ml-3">
                  {approvedEmailChanges.map((approval, index: number) => (
                    <dl className={generatedClasses.item} key={index}>
                      <div className={generatedClasses.dlItem}>
                        <dt className={generatedClasses.itemTitle}>By:</dt>
                        <dd className={generatedClasses.itemText}>
                          {approval?.authorEmailAddress}
                        </dd>
                      </div>
                      <div className={generatedClasses.dlItem}>
                        <dt className={generatedClasses.itemTitle}>
                          Date & time:
                        </dt>
                        <dd className={generatedClasses.itemText}>
                          {approval?.dateTime &&
                            format(new Date(approval?.dateTime), "kk:mm P")}
                        </dd>
                      </div>
                      {approval?.note && (
                        <div className={generatedClasses.dlItem}>
                          <dt className={generatedClasses.itemTitle}>
                            Reason:
                          </dt>
                          <dd className={generatedClasses.itemText}>
                            {approval?.note}
                          </dd>
                        </div>
                      )}
                    </dl>
                  ))}
                </div>
              </div>
            )}
            {rejectedEmailChanges?.length > 0 && (
              <div className="u-ml-4">
                <h4 className="u-m-0 u-my-2 u-font-bold">Rejection(s)</h4>
                <div className="u-ml-3">
                  {rejectedEmailChanges.map((rejection, index: number) => (
                    <dl className={generatedClasses.item} key={index}>
                      <div className={generatedClasses.dlItem}>
                        <dt className={generatedClasses.itemTitle}>By:</dt>
                        <dd className={generatedClasses.itemText}>
                          {rejection?.authorEmailAddress}
                        </dd>
                      </div>
                      <div className={generatedClasses.dlItem}>
                        <dt className={generatedClasses.itemTitle}>
                          Date & time:
                        </dt>
                        <dd className={generatedClasses.itemText}>
                          {rejection?.dateTime &&
                            format(new Date(rejection?.dateTime), "kk:mm P")}
                        </dd>
                      </div>
                      {rejection?.note && (
                        <div className={generatedClasses.dlItem}>
                          <dt className={generatedClasses.itemTitle}>
                            Reason:
                          </dt>
                          <dd className={generatedClasses.itemText}>
                            {rejection?.note}
                          </dd>
                        </div>
                      )}
                    </dl>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {isActiveClientPortalUser ? (
          <div>
            <div className={generatedClasses.itemTitle}>
              Remove or Add Client Portal page access:{" "}
            </div>
            <div>
              <FormContainer
                {...formConfig}
                classNames={{
                  formWrapper: generatedClasses.formWrapper,
                  fieldWrapper: generatedClasses.fieldWrapper,
                }}
                onSubmitAction={onFormSubmit(pageRestrictions!)}
              >
                <ErrorSummary />
                <Submitting />

                <div className={generatedClasses.actionButtonWrapper}>
                  {actions?.map(({ text, callback, props }, index) => (
                    <ActionButton
                      className="u-rounded-none u-w-fit"
                      {...props}
                      callback={() => callback?.(azureUserId)}
                      key={index}
                    >
                      <>{text}</>
                    </ActionButton>
                  ))}
                </div>
              </FormContainer>
            </div>
          </div>
        ) : (
          <div className={generatedClasses.actionButtonWrapper}>
            {actions?.map(({ text, callback, props }, index) => (
              <ActionButton
                className="u-rounded-none u-w-fit"
                {...props}
                callback={() => callback?.(azureUserId)}
                key={index}
              >
                <>{text}</>
              </ActionButton>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loading isActive={true} titleText={loadingText} />
  );
};
