import { Props } from "./interfaces";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export const Select = ({
  id,
  input,
  meta,
  options,
  label,
  isLabelHidden,
  setFieldRef,
  classes,
}: Props): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    setFieldRef(id, ref);
  }, []);

  const generatedClasses = {
    label: classNames("c-label tablet:u-mr-4", classes?.label, {
      ["u-sr-only"]: isLabelHidden,
    }),
  };

  const renderOptions = (options: Array<Record<string, any>>) => {
    return options?.map((option, index: number) => {
      const { value, displayText } = option;
      return (
        <option key={index} value={value}>
          {displayText}
        </option>
      );
    });
  };

  return (
    <>
      <label htmlFor={id} className={generatedClasses.label}>
        {label}
      </label>
      <div className="c-select_wrapper tablet:u-min-w-64">
        <select
          ref={ref}
          {...input}
          id={id}
          className="c-select u-inline-block u-font-family-bold u-pr-9"
        >
          {renderOptions(options)}
        </select>
        <span className="c-select_arrow"></span>
      </div>
    </>
  );
};
