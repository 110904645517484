import classNames from "classnames";
import { Props } from "./interfaces";

export const Footer = ({ primaryLogo, secondaryLogo }: Props): JSX.Element => {
  const generatedClasses: Record<string, string> = {
    footer: classNames("footer l-grid-container"),
    main: classNames("c-footer_main"),
    left: classNames("c-footer_left"),
    logo: classNames("c-footer_logo"),
    logoImg: classNames("c-footer_logo-image"),
    bottomLogo: classNames("c-footer_bottom-logo"),
    bottomLogoImg: classNames("c-footer_bottom-logo-image"),
  };

  return (
    <footer>
      <div className={generatedClasses.footer}>
        <div className={generatedClasses.main}>
          <div className={generatedClasses.left}>
            <div className={generatedClasses.logo}>
              <a href={primaryLogo.href}>
                <img
                  className={generatedClasses.logoImg}
                  src={primaryLogo.src}
                  alt={primaryLogo.alt}
                />
              </a>
            </div>
            {secondaryLogo && (
              <div className={generatedClasses.bottomLogo}>
                <a href={secondaryLogo?.href} target="_blank">
                  <img
                    className={generatedClasses.bottomLogoImg}
                    src={secondaryLogo?.src}
                    alt={secondaryLogo?.alt}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};
