import classNames from "classnames";
import ReactModal from "react-modal";
import { useEffect, useState } from "react";
import { ReactComponent as IconClose } from "../../img/svg-icons/icon-close.svg";
import { Props } from "./interface";
import { rootElementId } from "../../constants/context";

export const Dialog = ({
  isOpen,
  id,
  handleCloseDialog,
  children,
}: Props): JSX.Element => {
  const [shouldDialogOpen, setShouldDialogOpen] = useState<boolean>(isOpen);
  const appElement: any =
    typeof document !== "undefined"
      ? document.getElementById(rootElementId)
      : undefined;

  const generatedClasses: Record<string, string> = {
    backdrop: classNames(
      "u-z-5000 u-fixed u-top-0 u-flex u-items-center u-justify-center u-w-full u-h-full u-bg-black-transparent-06"
    ),
    dialog: classNames(
      "u-z-5000 u-absolute u-top-auto u-left-auto u-h-auto u-w-11/12 tablet:u-w-auto tablet:u-min-w-3/10 tablet:u-max-w-7/10 u-max-h-7/10 u-p-4 u-overflow-auto u-bg-white u-text-fiord-blue u-border-none u-transition-all"
    ),
    buttonWrapper: classNames("u-flex u-justify-end"),
    button: classNames(
      "u-text-teal-green u-bg-transparent u-border-none u-p-0 u-flex u-items-center u-cursor-pointer"
    ),
    closeIcon: classNames("u-w-7 u-h-7"),
  };

  useEffect(() => {
    setShouldDialogOpen(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      id={`${id}`}
      className={generatedClasses.dialog}
      isOpen={shouldDialogOpen}
      appElement={appElement}
      overlayClassName={generatedClasses.backdrop}
      onRequestClose={handleCloseDialog}
    >
      <div className={generatedClasses.buttonWrapper}>
        <button className={generatedClasses.button} onClick={handleCloseDialog}>
          <span className="u-sronly">Close</span>
          <IconClose
            aria-hidden="true"
            role="presentation"
            className={generatedClasses.closeIcon}
          />
        </button>
      </div>
      {children}
    </ReactModal>
  );
};
