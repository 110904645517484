import { dateValidator, DateValidator } from "../validators/date";
import {
  DateRangeValidator,
  dateRangeValidator,
} from "../validators/dateRange";
import { emailValidator, EmailValidator } from "../validators/email";
import { requiredValidator, RequiredValidator } from "../validators/required";
import { wordCountValidator } from "../validators/wordCountValidator";

export enum FieldValidatorTypes {
  Required,
  DateRange,
  Date,
  Email,
  MaxWordCount,
}

export interface FieldValidatorConfig {
  type: FieldValidatorTypes;
  message: string;
}

export type FieldValidatorConfigType =
  | RequiredValidator
  | DateValidator
  | DateRangeValidator
  | EmailValidator;

export const validators: Record<FieldValidatorTypes, any> = {
  [FieldValidatorTypes.Required]: requiredValidator,
  [FieldValidatorTypes.DateRange]: dateRangeValidator,
  [FieldValidatorTypes.Date]: dateValidator,
  [FieldValidatorTypes.Email]: emailValidator,
  [FieldValidatorTypes.MaxWordCount]: wordCountValidator,
};

export const validator = (
  data: Record<string, string>,
  rules: Record<string, any>
): Record<string, Array<string>> => {
  const errors: Record<string, Array<string>> = {};

  Object.keys(rules).forEach((key: string) => {
    const value = data[key];
    const fieldValidators = rules[key];
    errors[key] = [];

    fieldValidators?.forEach((fieldValidatorConfig: FieldValidatorConfig) => {
      const { type } = fieldValidatorConfig;
      const fieldValidator = validators[type];

      const error = fieldValidator(value, data, fieldValidatorConfig);

      if (error) {
        errors[key].push(error);
      }
    });
  });

  return errors;
};
