import { PublicProps as SelectProps } from "../components/forms/select/interfaces";
import { PublicProps as SearchProps } from "../components/forms/search/interfaces";
import { PublicProps as InputProps } from "../components/forms/input/interfaces";
import { PublicProps as CheckboxProps } from "../components/forms/checkbox/interfaces";
import { PublicProps as DatePickerProps } from "../components/forms/datepicker/interfaces";
import { PublicProps as NumberProps } from "../components/forms/number/interfaces";
import { PublicProps as TextAreaProps } from "../components/TextArea/interface";
import { FieldValidatorConfigType } from "../validation/validator";

export interface FormConfig {
  fields: Array<FormFieldTypes>;
  onSubmit?: (values: Record<string, string>) => Promise<any>;
}

export interface FormFieldConfig {
  id: string;
  validators: Array<FieldValidatorConfigType>;
  label: string;
  isLabelHidden?: boolean;
  className?: string;
  initialValue?: any;
}

export type FormFieldTypes =
  | InputProps
  | SelectProps
  | SearchProps
  | DatePickerProps
  | CheckboxProps
  | NumberProps
  | TextAreaProps;

export enum FieldRenderers {
  SELECT = "SELECT",
  DATE_PICKER = "DATE_PICKER",
  SEARCH = "SEARCH",
  INPUT = "INPUT",
  CHECKBOX = "CHECKBOX",
  TEXT_AREA = "TEXT_AREA",
  NUMBER = "NUMBER",
}

export interface QueryResponse {
  hasSubmitErrors?: boolean;
  submitErrors?: Record<string, string>;
  hasSubmitError?: boolean;
  submitError?: string;
}
