import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Props } from "./interfaces";

export const DatePicker = ({
  id,
  input,
  label,
  isLabelHidden,
  setFieldRef,
  min,
  max,
}: Props): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    setFieldRef(id, ref);
  }, []);

  const generatedClasses = {
    label: classNames("c-label tablet:u-mr-4", {
      ["u-sr-only"]: isLabelHidden,
    }),
  };

  return (
    <>
      <label htmlFor={id} className={generatedClasses.label}>
        {label}
      </label>
      <input
        {...input}
        type="date"
        ref={ref}
        id={id}
        min={min}
        max={max}
        className="c-input-text u-inline-block u-font-family-bold tablet:u-w-auto"
      />
    </>
  );
};
