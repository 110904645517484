import classNames from "classnames";
import { ReactComponent as IconChevUp } from "../../img/svg-icons/icon-chev-up.svg";
import { ReactComponent as IconChevDown } from "../../img/svg-icons/icon-chev-down.svg";
import { ReactComponent as IconUnsorted } from "../../img/svg-icons/unsorted.svg";
import { useEffect, useState } from "react";

export interface Props {
  children: React.ReactNode;
  callback?: Function;
  activeSort?: { column: string; isAscending: boolean };
}

export const SortButton = ({
  children,
  callback,
  activeSort,
}: Props): JSX.Element => {
  const { isAscending } = activeSort || {};

  const generatedClasses: Record<string, string> = {
    button: classNames(`u-bg-transparent u-p-0 u-m-0
            u-border-none u-text-base u-font-bold
            u-text-white
            u-flex u-items-center u-justify-center
            u-w-full u-h-full
            u-cursor-pointer
        `),
    icon: classNames("u-flex u-w-7 u-h-7"),
  };

  const handleClick = (): void => {
    callback?.();
  };

  return (
    <button className={generatedClasses.button} onClick={handleClick}>
      <span>{children}</span>
      <span aria-live="polite" className="u-sr-only">
        Sort {isAscending ? "descending" : "ascending"}
      </span>
      <span>
        {activeSort ? (
          isAscending ? (
            <IconChevUp className={generatedClasses.icon} />
          ) : (
            <IconChevDown className={generatedClasses.icon} />
          )
        ) : (
          <IconUnsorted className={generatedClasses.icon} />
        )}
      </span>
    </button>
  );
};
