import { QueryBanner } from "../../../components/queryBanner/queryBanner";
import { useContext, useState } from "react";
import { Pagination } from "../../../components/pagination";
import { AppContext, AppContextType } from "../../../constants/context";
import { reportingFormConfig } from "../../../formConfig/queryBanner/reporting";
import { Props } from "./interface";
import { AdvancedTable } from "../../../components/advancedTable";
import { reportingTableHeaders } from "../../../constants/tableHeaders";
import { ReactComponent as DownloadIcon } from "../../../img/svg-icons/icon-download.svg";
import { usePageDataQuery } from "../../../hooks/usePageDataQuery";
import { format } from "date-fns";
import { DEFAULT_DATE_INPUT_FORMAT } from "../../../constants";

export const ReportingTemplate = ({
  pageTitleText,
  noResultsText,
  queryBannerText = "Search reports or filter by:",
  tableCaptionText,
  pagination: paginationProps,
  tableData: tableDataProps,
  tableId = "UserAdminAppTable",
  sortableColumns: sortableColumnsProps,
  hiddenColumns: hiddenColumnsProps,
  apis,
  columnOrder,
  error: errorProp,
}: Props): JSX.Element => {
  const [formValues, setFormValues] = useState<Record<string, any>>({
    From: format(new Date(), DEFAULT_DATE_INPUT_FORMAT),
    To: format(new Date(), DEFAULT_DATE_INPUT_FORMAT),
  });
  const appContext = useContext<AppContextType>(AppContext);

  const {
    pageDataRequestError,
    activeSort,
    paginationState,
    tableDataState,
    onFormSubmit,
    onGet,
    onPageChange,
    onSort,
  } = usePageDataQuery(
    {
      formValues,
      tableDataProps,
      paginationProps,
      initialError: errorProp,
      apis,
      columnOrder,
    },
    { setFormValues }
  );
  const { totalCount, totalPages, pageNumber, pageSize } =
    paginationState ?? {};
  const hasTableData = tableDataState?.length > 0;
  const { userRoles } = appContext;

  const queryString: string = Object.entries(formValues)
    .map((entry) => entry.join("="))
    .join("&");

  return (
    <>
      <div className="u-bg-burnham-green u-text-center u-text-white u-m-0 u-p-2 u-to-viewport-edge-x">
        <h2>{pageTitleText}</h2>
      </div>
      <QueryBanner
        title={queryBannerText}
        formConfig={reportingFormConfig}
        onFormSubmit={onFormSubmit}
      />
      {hasTableData && (
        <div className="u-flex u-items-center u-gap-4 u-my-4">
          <DownloadIcon className="u-w-6 u-h-6" />
          <a
            className="u-font-medium u-text-lg u-text-teal-green"
            href={`${apis.download}?${queryString}`}
            download
          >
            Export to Excel
          </a>
        </div>
      )}
      <div className="u-flex u-justify-start u-my-5 u-w-full u-overflow-x-auto">
        {pageDataRequestError ? (
          <>{pageDataRequestError}</>
        ) : !hasTableData ? (
          <>{noResultsText}</>
        ) : (
          <AdvancedTable
            data={tableDataState}
            caption={tableCaptionText}
            id={tableId}
            sortableColumns={sortableColumnsProps}
            hiddenColumns={hiddenColumnsProps}
            sortFunction={onSort}
            activeSort={activeSort}
            columnHeaderTextMap={reportingTableHeaders}
          />
        )}
      </div>
      {hasTableData && (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          pageText={"Click to navigate to page"}
          totalCount={totalCount}
          totalPages={totalPages}
          setPageNumberAction={onPageChange}
          numberOfResults={tableDataState.length}
          ariaProps={{
            controls: tableId,
          }}
        />
      )}
    </>
  );
};
