import { tableHeaders } from "../../constants/tableHeaders";
import { ActionButton } from "../actionButton";
import { Props } from "./interface";

export const DeleteItem = ({
  item,
  rejectAction,
  acceptAction,
  error,
  cancelText = "CANCEL",
  confirmText = "DELETE",
  bodyText = "Are you sure you want to delete this item?",
  hiddenFields = [
    "id",
    "contentId",
    "registrationApprovalEmailBody",
    "registrationApprovalEmailSubject",
    "requestApprovalEmailBody",
    "requestApprovalEmailSubject",
    "cpdApplicable",
  ],
}: Props): JSX.Element => {
  const data: Record<string, any> = Object.assign({}, item);

  hiddenFields?.forEach((hiddenField: string) => {
    if (data.hasOwnProperty(hiddenField)) {
      delete data[hiddenField];
    }
  });

  return (
    <div className="u-p-3">
      <p>{bodyText}</p>
      <div>
        <dl>
          {Object.entries(data).map(([key, value], index) => (
            <div className="u-flex u-gap-4" key={index}>
              <dt className="u-font-bold">{tableHeaders[key] ?? key}:</dt>
              <dd className="u-m-0 u-p-0">{`${value ?? ""}`}</dd>
            </div>
          ))}
        </dl>
      </div>
      {error && (
        <div aria-live="polite" className="c-error-summary">
          <span>{error}</span>
        </div>
      )}
      <div className="u-flex u-mx-2 u-gap-2 u-flex-wrap u-mt-8 u-w-full u-justify-between">
        <ActionButton
          className="u-rounded-none u-w-fit"
          callback={rejectAction}
        >
          {cancelText}
        </ActionButton>
        <ActionButton
          className="u-rounded-none u-w-fit"
          callback={acceptAction}
        >
          {confirmText}
        </ActionButton>
      </div>
    </div>
  );
};
