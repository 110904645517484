import { useLoaderData } from "react-router-dom";
import { SettingsTemplateInnerDialog } from "../../../components/modal-renderers/settingsTemplate";
import { SettingsPageTemplate } from "../../templates/settingsPageTemplate";

import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import { dataKeys, tableHeaders } from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import {
  FieldRenderers,
  FormConfig,
  FormFieldTypes,
} from "../../../types/form";
import { FieldValidatorTypes } from "../../../validation/validator";

export const ClientPortalPageRestrictions = (): JSX.Element => {
  let { data, error }: any = useLoaderData();

  const {
    hiddenColumns: hidden,
    order,
    sortable,
  } = columnConfig.clientPortalPageRestrictions;
  data = orderColumns(data, order);

  const generateFormConfig = (item: Record<string, string>): FormConfig => {
    const pageNameField: FormFieldTypes = {
      id: dataKeys.pageName,
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${
            tableHeaders[dataKeys.pageName] ?? dataKeys.pageName
          } is required`,
        },
      ],
      label: tableHeaders[dataKeys.pageName] ?? dataKeys.pageName,
      isLabelHidden: false,
      initialValue: item[dataKeys.pageName],
      classes: {
        label: "o-form-label u-font-bold u-text-lg",
      },
    };

    const restrictionIdField: FormFieldTypes = {
      id: dataKeys.restrictionId,
      renderer: FieldRenderers.NUMBER,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${
            tableHeaders[dataKeys.restrictionId] ?? dataKeys.restrictionId
          } is required`,
        },
      ],
      label: tableHeaders[dataKeys.restrictionId] ?? dataKeys.restrictionId,
      isLabelHidden: false,
      initialValue: item[dataKeys.restrictionId],
      classes: {
        label: "o-form-label u-font-bold u-text-lg",
      },
    };

    const readWriteField: FormFieldTypes = {
      id: dataKeys.hasReadWriteOption,
      renderer: FieldRenderers.CHECKBOX,
      type: "checkbox",
      validators: [],
      initialValue: item[dataKeys.hasReadWriteOption],
      label: "Read/write available:",
      classes: {
        label: "o-form-label u-font-bold u-text-lg",
      },
    };

    return {
      fields: [pageNameField, restrictionIdField, readWriteField],
    };
  };

  const newItemFormData: Record<string, string> = {
    pageName: "",
    restrictionId: "",
  };

  return (
    <SettingsPageTemplate
      tableData={data}
      sortableColumns={sortable}
      hiddenColumns={hidden}
      columnOrder={order}
      pageTitleText="Client Portal Page Restrictions"
      modalText="Client portal page restrictions"
      editWarningText="Note: If you change the Restriction ID please remember to update the Restriction ID on the relevant pages within the CMS"
      noResultsText="There are no portal users whose email address contains your search term. Please search again"
      addNewItemText="Add new restriction"
      saveText="Save restriction"
      cancelText="Cancel"
      tableCaptionText="Client portal page restrictions"
      InnerDialogRenderer={SettingsTemplateInnerDialog}
      generateFormConfig={generateFormConfig}
      newItemFormData={newItemFormData}
      viewText="Edit"
      dataItemKey={dataKeys.restrictionId}
      apis={{
        get: routeApis[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS]
          .getPageRestrictions,
        update:
          routeApis[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS].editPageRestriction,
      }}
      error={error}
    />
  );
};
