import { Routes } from "./routes";

interface PendingApi {
  getPendingItems: string;
  resubmitPendingItem: string;
  rejectPendingItem: string;
}

interface APIS {
  [Routes.HOME]: {};
  [Routes.PENDING_REGISTRATIONS]: PendingApi;
  [Routes.PENDING_RECORDS]: PendingApi;
  [Routes.PENDING_REQUESTS]: PendingApi;
  [Routes.PENDING_EMAIL_CHANGES]: PendingApi;
  [Routes.USER_MANAGEMENT]: {};
  [Routes.PORTAL_USERS]: {
    portalUsers: string;
    userPageRestrictions: string;
  };
  [Routes.EMAIL_LOG]: {
    getEmailLog: string;
  };
  [Routes.SETTINGS]: {};
  [Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS]: {
    getPageRestrictions: string;
    editPageRestriction: string;
    deletePageRestriction: string;
  };
  [Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]: {
    getSalesforceCampaigns: string;
    editSalesforceCampaigns: string;
    deleteSalesforceCampaigns: string;
  };
  [Routes.ADMIN]: {};
  [Routes.REPORTS]: {
    getReports: string;
    downloadReports: string;
  };
}

export const authApis = {
  authLogin:
    process.env.NODE_ENV === "development"
      ? `https://localhost:7010/api/Auth/Login`
      : `/api/Auth/Login`,
  isAuthenticated: "/api/Auth/IsUserAuthenticated",
  userRole: "/api/Auth/GetUserRole",
};

// mock urls will be updated once the real api is ready in the dev application
export const routeApis: APIS = {
  [Routes.HOME]: {},
  [Routes.PENDING_REGISTRATIONS]: {
    getPendingItems: "/api/pendingRegistrations",
    resubmitPendingItem: "/api/pendingRegistrations/Resubmit",
    rejectPendingItem: "/api/pendingRegistrations/Reject",
  },
  [Routes.PENDING_RECORDS]: {
    getPendingItems: "",
    resubmitPendingItem: "",
    rejectPendingItem: "",
  },
  [Routes.PENDING_REQUESTS]: {
    getPendingItems: "/api/pendingAccessRequests",
    resubmitPendingItem: "/api/pendingAccessRequests/ReSubmit",
    rejectPendingItem: "/api/pendingAccessRequests/Reject",
  },
  [Routes.PENDING_EMAIL_CHANGES]: {
    getPendingItems: "/api/pendingEmailChanges",
    resubmitPendingItem: "/api/pendingEmailChanges/ApproveEmailChangeRequest",
    rejectPendingItem: "/api/pendingEmailChanges/RejectEmailChangeRequest",
  },
  [Routes.USER_MANAGEMENT]: {},
  [Routes.PORTAL_USERS]: {
    portalUsers: "/api/PortalUsers",
    userPageRestrictions: "/api/PortalUsers/UserPageRestrictions",
  },
  [Routes.EMAIL_LOG]: {
    getEmailLog: "/api/EmailLog",
  },
  [Routes.SETTINGS]: {},
  [Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS]: {
    getPageRestrictions: "/api/ClientPortalPageRestrictions",
    editPageRestriction: "/api/ClientPortalPageRestrictions",
    deletePageRestriction: "/api/ClientPortalPageRestrictions",
  },
  [Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]: {
    getSalesforceCampaigns: "/api/ClientPortalSalesforceCampaign",
    editSalesforceCampaigns: "/api/ClientPortalSalesforceCampaign",
    deleteSalesforceCampaigns: "/api/ClientPortalSalesforceCampaign",
  },
  [Routes.ADMIN]: {},
  [Routes.REPORTS]: {
    getReports: "/api/auditLog",
    downloadReports:
      process.env.NODE_ENV === "development"
        ? "https://localhost:7010/api/auditLog/DownloadAuditLog"
        : "/api/auditLog/DownloadAuditLog",
  },
};
