export const enum Roles {
  ClientPortalAdmin = "ClientPortal.Administrator",
  None = "None",
}

export const enum Portal {
  ClientPortal = "ClientPortal",
}

export const enum ClientPortalPageRestrictionPermissions {
  NoAccess = "NoAccess",
  ReadOnly = "ReadOnly",
  ReadWrite = "ReadWrite",
}
