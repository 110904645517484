import { FormContainer } from "../../containers/form";
import { FormConfig } from "../../types/form";
import { ActionButton } from "../actionButton";
import classNames from "classnames";

export interface Props {
  acceptAction: Function;
  cancelAction: Function;
  confirmationText?: string;
  formConfig?: FormConfig;
  onFormSubmit?: (values: Record<string, string>) => Promise<any>;
}

export const ConfirmHandler = ({
  acceptAction,
  cancelAction,
  confirmationText = "Are you sure?",
  formConfig,
  onFormSubmit,
}: Props) => {
  const generatedClasses = {
    formWrapper: classNames("c-form u-my-4"),
    fieldWrapper: classNames(
      "o-form-item u-flex u-flex-col u-flex-wrap u-gap-2"
    ),
  };
  return (
    <div className="u-flex u-flex-col u-gap-3">
      <p className="u-m-0">{confirmationText}</p>
      {formConfig && onFormSubmit ? (
        <FormContainer
          {...formConfig}
          classNames={{
            formWrapper: generatedClasses.formWrapper,
            fieldWrapper: generatedClasses.fieldWrapper,
          }}
          onSubmitAction={onFormSubmit}
          shouldRenderErrorsInline={true}
        >
          <div className="u-flex u-justify-between">
            <ActionButton callback={cancelAction} className="u-w-auto u-px-8">
              No
            </ActionButton>
            <ActionButton className="u-w-auto u-px-8" {...{ type: "submit" }}>
              Yes
            </ActionButton>
          </div>
        </FormContainer>
      ) : (
        <div className="u-flex u-justify-between">
          <ActionButton callback={cancelAction} className="u-w-auto u-px-8">
            No
          </ActionButton>
          <ActionButton callback={acceptAction} className="u-w-auto u-px-8">
            Yes
          </ActionButton>
        </div>
      )}
    </div>
  );
};
