import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import {
  dataKeys,
  portalUsersTableHeaders,
} from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import { updatePortalUser } from "../../../loaders/pages/portalUsers";
import { UserManagementPageTemplate } from "../../templates/userManagementPageTemplate";

export const PortalUsers = (): JSX.Element => {
  let { data, pagination, error }: any = useLoaderData();

  const { hiddenColumns: hidden, order, sortable } = columnConfig.portalUsers;
  data = orderColumns(data, order);

  return (
    <UserManagementPageTemplate
      tableData={data}
      sortableColumns={sortable}
      hiddenColumns={hidden}
      columnOrder={order}
      pagination={pagination}
      pageTitleText={"Portal Users"}
      modalText={"Edit User"}
      cancelText="Cancel"
      saveText="Save user details"
      noResultsText="There are no portal users whose email address contains your search term. Please search again"
      tableCaptionText="Portal users"
      viewText="Edit"
      dataItemKey={dataKeys.email}
      apis={{
        get: routeApis[Routes.PORTAL_USERS].portalUsers,
      }}
      updateApi={updatePortalUser}
      error={error}
      columnHeaderTextMap={portalUsersTableHeaders}
    />
  );
};
