import { RequestMethods } from "../../constants/fetch";
import { activateBasicFetch, setFetchOpts } from "../../helpers/fetch";
import { FetchResponse } from "../../types/fetch";

export const reSubmitPendingItem = async (
  url: string,
  data: any
): Promise<FetchResponse> => {
  const { azureUserId } = data;

  url += `?userId=${azureUserId}`;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
  });

  return activateBasicFetch(url, fetchOptions);
};

export const rejectPendingItem = async (
  url: string,
  data: any
): Promise<FetchResponse> => {
  const { azureUserId, note } = data;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: { userId: azureUserId, note },
  });

  return activateBasicFetch(url, fetchOptions);
};
