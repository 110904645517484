import classNames from "classnames";
import { Props, Row } from "./interface";

export const Table = ({
  id,
  columns,
  rows,
  footer,
  isStriped,
  caption,
}: Props): JSX.Element => {
  const tableFooterRow: Row | null | undefined = footer ? rows.pop() : null;

  const generatedClasses: Record<string, string> = {
    srOnly: classNames("u-sr-only"),
    theadCell: classNames(
      "u-text-white u-bg-fiord-blue u-px-2 u-py-1 u-text-base u-w-max u-whitespace-nowrap"
    ),
    tbodyRow: classNames("", {
      ["even:u-bg-mercury-grey"]: isStriped,
    }),
    tbodyCell: classNames("u-py-1 u-p-2"),
    tfootCell: classNames("u-bg-fiord-blue-transparent"),
  };

  return (
    <table className="u-m-auto" id={id}>
      <caption className={generatedClasses.srOnly}>{caption}</caption>
      <thead>
        <tr>
          {columns.map(({ text, Renderer, props }, index) => {
            const { activeSort } = props ?? {};
            const hasSort: boolean = Boolean(activeSort) ?? false;
            const ariaSort: "ascending" | "descending" | "none" = hasSort
              ? activeSort.isAscending
                ? "ascending"
                : "descending"
              : "none";

            return (
              <th
                scope="col"
                className={generatedClasses.theadCell}
                key={index}
                aria-sort={ariaSort}
              >
                {Renderer ? <Renderer {...props}>{text}</Renderer> : text}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIndex) => (
          <tr className={generatedClasses.tbodyRow} key={rowIndex}>
            {row.children.map(({ text, Renderer, props }, cellIndex) => (
              <td className={generatedClasses.tbodyCell} key={cellIndex}>
                {Renderer ? <Renderer {...props}>{text}</Renderer> : text}
              </td>
            ))}
          </tr>
        ))}
      </tbody>

      {tableFooterRow ? (
        <tfoot>
          <tr>
            {tableFooterRow.children.map(({ text, Renderer }, index) => (
              <td className={generatedClasses.tfootCell} key={index}>
                {Renderer ? <Renderer>{text}</Renderer> : text}
              </td>
            ))}
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
};
