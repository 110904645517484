import { routeApis } from "../../constants/apis";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const reportingLoader = async (props: any) => {
  const today: string = new Date().toISOString().split("T")[0];

  const defaultParams = {
    From: today,
    To: today,
  };

  props["params"] = {
    ...(props?.params ?? {}),
    ...defaultParams,
  };
  return getPageData({
    url: routeApis[Routes.REPORTS].getReports,
    ...props,
  });
};
