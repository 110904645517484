import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import { dataKeys } from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import { EmailPageTemplate } from "../../templates/emailPageTemplate";

export const EmailLogPage = () => {
  let { data, pagination, error }: any = useLoaderData();

  const { hiddenColumns: hidden, order, sortable } = columnConfig.emailLog;
  data = orderColumns(data, order);

  return (
    <EmailPageTemplate
      pageTitleText="Email Log"
      modalText="Email Log"
      noResultsText="There are no emails that match your search term. Please search again"
      tableCaptionText="Email Logs"
      tableData={data}
      pagination={pagination}
      hiddenColumns={hidden}
      sortableColumns={sortable}
      columnOrder={order}
      viewText="View"
      dataItemKey={dataKeys.emailAddress}
      apis={{
        get: routeApis[Routes.EMAIL_LOG].getEmailLog,
      }}
      error={error}
    />
  );
};
