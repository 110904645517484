import { routeApis } from "../../constants/apis";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const pendingEmailChangesLoader = async (props: any) => {
  return await getPageData({
    url: routeApis[Routes.PENDING_EMAIL_CHANGES].getPendingItems,
    ...props,
  });
};
