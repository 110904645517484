import { format } from "date-fns";
import { DEFAULT_DATE_INPUT_FORMAT } from "../../constants";
import { EmailTypes, fieldIds } from "../../constants/fields";
import { FieldRenderers, FormFieldTypes } from "../../types/form";
import { FieldValidatorTypes } from "../../validation/validator";

export const queryBannerFields: Record<string, FormFieldTypes> = {
  emailAddress: {
    id: fieldIds.EMAIL_ADDRESS,
    renderer: FieldRenderers.SEARCH,
    validators: [],
    placeholder: "Enter email address",
    label: "search",
    isLabelHidden: true,
  },
  emailType: {
    id: fieldIds.EMAIL_TYPE,
    renderer: FieldRenderers.SELECT,
    validators: [],
    options: [
      {
        value: "",
        displayText: "Select email type",
      },
      {
        value: EmailTypes.ApprovedRegistration,
        displayText: "Approved registration",
      },
      {
        value: EmailTypes.RejectedRegistration,
        displayText: "Rejected registration",
      },
      {
        value: EmailTypes.ChangeEmailAddressRequestApproval,
        displayText: "Change email address request approval",
      },
      {
        value: EmailTypes.ChangeEmailAddressRequestRejection,
        displayText: "Change email address request rejection",
      },
      {
        value: EmailTypes.PortalAccessRequestApproval,
        displayText: "Portal access request approval",
      },
      {
        value: EmailTypes.PortalAccessRequestRejection,
        displayText: "Portal access request rejection",
      },
    ],
    label: "Select email type",
    isLabelHidden: true,
  },
  fromDate: {
    id: fieldIds.FROM_DATE,
    renderer: FieldRenderers.DATE_PICKER,
    validators: [
      {
        type: FieldValidatorTypes.Date,
        message: "From date must be a valid date",
        isPastOnly: true,
        pastOnlyMessage: "From date must be in the past",
      },
      {
        type: FieldValidatorTypes.DateRange,
        message: "The From date must be on or before the To date",
        isPastOnly: true,
        fieldUniqueIds: [fieldIds.FROM_DATE, fieldIds.TO_DATE],
      },
    ],
    label: "From:",
    max: format(Date.now(), DEFAULT_DATE_INPUT_FORMAT),
  },
  toDate: {
    id: fieldIds.TO_DATE,
    renderer: FieldRenderers.DATE_PICKER,
    validators: [
      {
        type: FieldValidatorTypes.Date,
        message: "From date must be a valid date",
        isPastOnly: true,
        pastOnlyMessage: "To date must be in the past",
      },
      {
        type: FieldValidatorTypes.DateRange,
        message: "The To date must be on or after the From date",
        isPastOnly: true,
        fieldUniqueIds: [fieldIds.FROM_DATE, fieldIds.TO_DATE],
      },
    ],
    label: "To:",
    max: format(Date.now(), DEFAULT_DATE_INPUT_FORMAT),
  },
};
