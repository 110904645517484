import classNames from "classnames";
import { tableHeaders } from "../../../constants/tableHeaders";
import { ActionButton } from "../../actionButton";
import { ErrorSummary } from "../../forms/errorSummary";
import { Props } from "../interface";

export const PendingInnerTemplateDialog = ({
  data,
  title,
  actions,
  error,
  columnHeaderTextmap = tableHeaders,
  hiddenFields,
}: Props): JSX.Element => {
  const { id, ...item } = data ?? {};

  hiddenFields?.forEach((hiddenField: string) => {
    if (item.hasOwnProperty(hiddenField)) {
      delete item[hiddenField];
    }
  });

  const generatedClasses: Record<string, string> = {
    buttonWrapper: classNames("u-flex u-justify-end u-mb-6"),
    button: classNames(
      "u-bg-teal-green u-text-white u-border-none u-p-0 u-rounded-xl u-my-2 u-flex u-items-center"
    ),
    svgButton: classNames(
      "u-bg-transparent u-border-none u-p-0 u-m-0 u-text-fiord-blue u-py-2 u-flex u-items-center"
    ),
    item: classNames("u-flex u-flex-col u-items-left"),
    dlItem: classNames("u-flex u-gap-2 u-flex-wrap u-my-2"),
    itemTitle: classNames("u-font-bold"),
    itemText: classNames("u-p-0 u-m-0"),
  };
  return (
    <div className="u-p-3">
      <h2 className="u-p-0 u-m-0">{title}</h2>
      <dl className={generatedClasses.item}>
        {Object.keys(item).map((key, index) => (
          <div className={generatedClasses.dlItem} key={index}>
            <dt className={generatedClasses.itemTitle}>
              {columnHeaderTextmap[key] ?? key}:
            </dt>
            <dd className={generatedClasses.itemText}>{`${
              data[key] ?? ""
            }`}</dd>
          </div>
        ))}
      </dl>
      <div className="u-flex u-justify-between u-mx-2 u-gap-2 u-flex-wrap">
        {actions?.map(({ text, callback }, index) => (
          <ActionButton
            className="u-rounded-none u-w-fit"
            callback={() => callback?.(data)}
            key={index}
          >
            <>{text}</>
          </ActionButton>
        ))}
      </div>
      {error && (
        <div className="u-py-2">
          <ErrorSummary globalError={error} />
        </div>
      )}
    </div>
  );
};
