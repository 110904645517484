import { GenerateButtonsRenderer } from "../../helpers/action-button-generators";
import {
  generateColumnHeaders,
  generateRows,
} from "../../helpers/table-generators";
import { Table } from "../table";
import { Cell, Row } from "../table/interface";
import { Props } from "./interface";

// wrapping component for standard table containing specific handling for behaviour
// on admin pages.
export const AdvancedTable = ({
  caption,
  id,
  data = [],
  sortableColumns,
  hiddenColumns,
  activeSort,
  columnHeaderTextMap,
  actions,
  sortFunction,
}: Props) => {
  const columns: Array<Cell> = generateColumnHeaders({
    data: data?.[0] || {},
    sortableColumns: sortableColumns,
    hiddenColumns: hiddenColumns,
    sortFn: sortFunction,
    activeSort,
    columnHeaderTextMap: columnHeaderTextMap,
  });

  //Add a new column for action buttons
  if (actions) {
    columns.push({
      text: "Actions you can take",
      Renderer: ({ children }) => <span className="u-sr-only">{children}</span>,
    });
  }

  const rows: Array<Row> = generateRows({
    data: data || {},
    actions: actions,
    hiddenColumns: hiddenColumns,
  });

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={false}
      isStriped={true}
      caption={caption}
      id={id}
    />
  );
};
