import { RequestMethods } from "../../constants/fetch";
import { activateBasicFetch, setFetchOpts } from "../../helpers/fetch";
import { FetchResponse } from "../../types/fetch";

export const updateSettingItem = async ({
  url,
  item,
}: {
  url: string;
  item: Record<string, string>;
}): Promise<FetchResponse> => {
  const fetchOptions = setFetchOpts({
    method: RequestMethods.PUT,
    body: item,
  });

  return activateBasicFetch(url, fetchOptions);
};

export const addSettingData = async ({
  url,
  item,
}: {
  url: string;
  item: Record<string, string>;
}): Promise<FetchResponse> => {
  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: item,
  });

  return activateBasicFetch(url, fetchOptions);
};

export const deleteSettingItem = async ({
  url,
}: {
  url: string;
}): Promise<FetchResponse> => {
  const fetchOptions = setFetchOpts({
    method: RequestMethods.DELETE,
  });

  return activateBasicFetch(url, fetchOptions);
};
