import { routeApis } from "../../constants/apis";
import { clientPortalPageRestrictions } from "../../constants/columnConfig";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const clientPortalPageRestrictionsLoader = async (props: any) => {
  return getPageData({
    url: routeApis[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS].getPageRestrictions,
    ...props,
  });
};
