import { ActionButton } from "../../components/actionButton";
import { ComponentAction, ComponentActions } from "../../types/global";

interface Props {
  actions?: ComponentActions;
  data: any;
}

export const GenerateButtonsRenderer =
  ({ actions, data }: Props): React.FC =>
  (): JSX.Element => {
    return (
      <span className="u-flex u-gap-2">
        {actions?.map(
          (
            { props, text, srText, callback }: ComponentAction,
            index: number
          ) => {
            const handleClick = (): void => {
              callback?.(data);
            };

            let screenReaderText: string =
              typeof srText === "string" ? srText : srText?.(data) || "";
            let formattedText: string =
              typeof text === "string" ? text : text?.(data);

            return (
              <ActionButton key={index} {...props} callback={handleClick}>
                {formattedText}
                {screenReaderText && (
                  <span className="u-sr-only">{" " + screenReaderText}</span>
                )}
              </ActionButton>
            );
          }
        )}
      </span>
    );
  };
