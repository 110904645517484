import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { Editor } from "@tinymce/tinymce-react";

import { RichText } from "../RichText";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { Props } from "./interface";

/**
 * A more generic version can be found at https://github.com/nhsengland/futurenhs/tree/main/futurenhs.app/components/forms/TextArea
 */
export const TextArea: (props: Props) => JSX.Element = ({
  input,
  label,
  hint,
  shouldRenderAsRte,
  rteToolBarOptions = "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
  minHeight = 200,
  classes,
  rteFocusCallback,
}) => {
  const editorRef: any = useRef(null);
  const textAreaRef = useRef(null);

  const entry = useIntersectionObserver(textAreaRef, {
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting || !globalThis.IntersectionObserver;

  const [shouldLoadRte, setShouldLoadRte] = useState<boolean>(false);
  const [isRteFocussed, setIsRteFocussed] = useState<boolean>(false);

  const id: string = input.name;

  const elementMinHeight: string = `${minHeight}px`;

  const handleRteInit = (_: any, editor: any) => {
    editorRef.current = editor;
    editorRef.current?.on?.("CloseWindow", () => {
      const { top } =
        editorRef.current?.contentAreaContainer.getBoundingClientRect() ?? {};

      if (top) {
        window.scrollTo(0, top);
      }
    });
  };
  const handleRteChange = (value: any) => {
    input.onChange(value);
  };
  const handleRteFocus = () => {
    input.onFocus();
    setIsRteFocussed(true);
  };
  const handleRteBlur = () => {
    input.onBlur();
    setIsRteFocussed(false);
  };

  const generatedIds: any = {
    hint: `${id}-hint`,
    errorLabel: `${id}-error`,
    remainingCharacters: `${id}-remaining-characters`,
    label: `${id}-label`,
  };

  const generatedClasses: any = {
    wrapper: classNames("u-w-full"),
    label: classNames(classes?.label),
    hint: "",
    error: "",
    inputWrapper: "",
    input: classNames("u-w-full", {
      ["u-invisible"]: shouldLoadRte,
    }),
  };

  useEffect(() => {
    isVisible && shouldRenderAsRte && setShouldLoadRte(true);
  }, [isVisible]);

  useEffect(() => {
    rteFocusCallback?.(isRteFocussed);
  }, [isRteFocussed]);

  return (
    <div className={generatedClasses.wrapper}>
      <label
        htmlFor={id}
        className={generatedClasses.label}
        id={generatedIds.label}
      >
        {label}
      </label>
      {hint && (
        <RichText
          id={generatedIds.hint}
          className={generatedClasses.hint}
          bodyHtml={hint}
          wrapperElementType="span"
        />
      )}
      {shouldLoadRte ? (
        <div
          className={generatedClasses.inputWrapper}
          style={{
            minHeight: editorRef.current ? "auto" : elementMinHeight,
          }}
          aria-labelledby={generatedIds.label}
          tabIndex={0}
        >
          <Editor
            tinymceScriptSrc={"/js/tinymce/tinymce.min.js"}
            textareaName={input.name}
            id={id}
            value={input.value}
            onInit={handleRteInit}
            onEditorChange={handleRteChange}
            onFocus={handleRteFocus}
            onBlur={handleRteBlur}
            init={{
              readonly: true,
              menubar: true,
              promotion: false,
              plugins:
                "autosave link lists hr anchor visualblocks visualchars fullscreen nonbreaking autolink lists table emoticons charmap advlist autolink image preview anchor searchreplace code insertdatetime media paste help",
              toolbar: rteToolBarOptions,
              content_style:
                "body { font-family: Helvetica, Arial, sans-serif; font-size: 19px; }",
              iframe_aria_text:
                "Rich Text Area. To navigate to the formatting toolbar press OPTION-F10 if you are an Apple user or ALT-F10 for all other users. Make sure your screen reader is in Focus or Auto Forms Mode.",
            }}
          />
        </div>
      ) : (
        <div
          className={generatedClasses.inputWrapper}
          style={{
            minHeight: editorRef.current ? "auto" : elementMinHeight,
          }}
        >
          <textarea
            {...input}
            ref={textAreaRef}
            id={id}
            className={generatedClasses.input}
            style={{ minHeight: elementMinHeight }}
          />
        </div>
      )}
    </div>
  );
};
