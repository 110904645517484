// field Id's, these match the id's used on the server as well as the param names.
export const PORTAL = "Portal";
export const EMAIL_TYPE = "EmailType";
export const FROM_DATE = "From";
export const TO_DATE = "To";
export const EMAIL_ADDRESS = "EmailAddress";

export const fieldIds = {
  PORTAL,
  EMAIL_TYPE,
  FROM_DATE,
  TO_DATE,
  EMAIL_ADDRESS,
};

export enum EmailTypes {
  ApprovedRegistration = "ApprovedRegistration",
  RejectedRegistration = "RejectedRegistration",
  PortalAccessRequestApproval = "PortalAccessRequestApproval",
  PortalAccessRequestRejection = "PortalAccessRequestRejection",
  ChangeEmailAddressRequestApproval = "ChangeEmailAddressRequestApproval",
  ChangeEmailAddressRequestRejection = "ChangeEmailAddressRequestRejection",
}
