import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import { orderColumns } from "../../../helpers/utils";
import { ReportingTemplate } from "../../templates/reportingTemplate";

export const Reporting = () => {
  let { data, pagination, error }: any = useLoaderData();
  const { hiddenColumns: hidden, order, sortable } = columnConfig.reporting;

  data = orderColumns(data, order);
  return (
    <ReportingTemplate
      tableData={data}
      sortableColumns={sortable}
      hiddenColumns={hidden}
      columnOrder={order}
      pagination={pagination}
      pageTitleText="Reporting"
      noResultsText="There are no reports to show. Please try again"
      tableCaptionText="Reports"
      apis={{
        get: routeApis[Routes.REPORTS].getReports,
        download: routeApis[Routes.REPORTS].downloadReports,
      }}
      error={error}
    />
  );
};
