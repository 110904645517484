import { useState } from "react";
import { OnGet } from "../../routes/templates/emailPageTemplate/interface";
import { orderColumns } from "../../helpers/utils";
import {
  getPageData,
  PageDataPagination,
  PageDataRequestInterface,
} from "../../loaders/templates/page";

export const usePageDataQuery = (
  pageData: Record<string, any>,
  pageFunctions: Record<string, Function>
): any => {
  const {
    formValues,
    tableDataProps,
    paginationProps,
    initialError,
    apis,
    columnOrder,
  } = pageData;
  const { setFormValues } = pageFunctions;

  const [pageDataRequestError, setError] = useState<any>(initialError);
  const [activeSort, setActiveSort] = useState<
    { column: string; isAscending: boolean } | undefined
  >();
  const [paginationState, setPaginationState] = useState<
    PageDataPagination | undefined
  >(paginationProps || undefined);
  const [tableDataState, setTableDataState] = useState<
    Array<Record<string, any>>
  >(tableDataProps || {});

  const onFormSubmit = async (formValues: Record<string, string>) => {
    setFormValues(formValues);

    return onGet({
      formValues,
      pagination: paginationState,
      sorting: activeSort ? activeSort : undefined,
    });
  };

  const onGet = ({ formValues, pagination, sorting }: OnGet) =>
    new Promise<any>(async (resolve: any, reject: any) => {
      setError(null);

      const response = await getPageData({
        url: apis.get,
        formValues: formValues,
        pagination: pagination,
        sorting,
      });

      let {
        data: dataResponse,
        pagination: paginationResponse,
        validationErrors,
        error,
      }: PageDataRequestInterface = response;

      // if validation errors, return and dont overwrite state.
      if (validationErrors) {
        return resolve({
          hasSubmitErrors: true,
          submitErrors: validationErrors,
        });
      }

      if (error) {
        setError(error);
      } else {
        dataResponse = orderColumns(dataResponse, columnOrder);
        setTableDataState(dataResponse);
        setPaginationState(paginationResponse);
      }

      if (sorting && sorting?.isUpdate) {
        const { column, isAscending } = sorting;

        setActiveSort({
          column,
          isAscending,
        });
      }

      // resolve to satisfy final form
      return resolve({});
    });

  const onPageChange = (pageNumber: number) => {
    const newPagination = Object.assign({}, paginationState, {
      pageNumber: pageNumber,
    });

    return onGet({
      formValues,
      pagination: newPagination,
      sorting: activeSort ? activeSort : undefined,
    });
  };

  const onSort = (column: string) => {
    const isAscending =
      column === activeSort?.column ? !activeSort.isAscending : true;

    onGet({
      formValues,
      pagination: paginationState,
      sorting: {
        column,
        isAscending,
        isUpdate: true,
      },
    });
  };

  return {
    pageDataRequestError,
    activeSort,
    paginationState,
    tableDataState,
    onFormSubmit,
    onGet,
    onPageChange,
    onSort,
    setError,
  };
};
