import { routeApis } from "../../constants/apis";
import { RequestMethods } from "../../constants/fetch";
import { Routes } from "../../constants/routes";
import { activateBasicFetch, setFetchOpts } from "../../helpers/fetch";
import { getPageData } from "../templates/page";

export const pendingRegistrationsLoader = async (props: any) => {
  return await getPageData({
    url: routeApis[Routes.PENDING_REGISTRATIONS].getPendingItems,
    ...props,
  });
};

export const reSubmitPendingRegistration = async (url: string, data: any) => {
  const { azureUserId, email, portal } = data;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: {
      azureUserId,
      email,
      portal,
    },
  });

  return await activateBasicFetch(url, fetchOptions);
};

export const rejectPendingRegistration = async (url: string, data: any) => {
  const { azureUserId, portal, email } = data;

  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: {
      azureUserId,
      email,
      portal,
    },
  });

  return await activateBasicFetch(url, fetchOptions);
};
