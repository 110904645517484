import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import {
  dataKeys,
  pendingEmailChangesTableHeaders,
} from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import { PendingPagesTemplate } from "../../templates/pendingPageTemplate";

export const PendingEmailChanges = () => {
  let { data, pagination, error }: any = useLoaderData();

  const { hiddenColumns, order, sortable, hiddenFields } =
    columnConfig.pendingEmailChangesColumnOrder;
  data = orderColumns(data, order);

  return (
    <PendingPagesTemplate
      tableData={data}
      hiddenColumns={hiddenColumns}
      columnOrder={order}
      sortableColumns={sortable}
      hiddenModalFields={hiddenFields}
      pagination={pagination}
      pageTitleText="Pending Email Changes"
      modalText="Pending Email Change"
      noResultsText="There are no pending email changes whose email address contains your search term. Please search again"
      resubmitText="Accept change"
      rejectText="Reject change"
      tableCaptionText="Pending email changes"
      queryBannerText="Search email changes or filter by:"
      confirmHandlerText="Are you sure you would like to reject this pending email change?"
      viewText="View"
      dataItemKey={dataKeys.email}
      apis={{
        get: routeApis[Routes.PENDING_EMAIL_CHANGES].getPendingItems,
        reject: routeApis[Routes.PENDING_EMAIL_CHANGES].rejectPendingItem,
        accept: routeApis[Routes.PENDING_EMAIL_CHANGES].resubmitPendingItem,
      }}
      shouldIncludeRejectionNote={true}
      columnHeaderTextMap={pendingEmailChangesTableHeaders}
      error={error}
    />
  );
};
