import { RequestMethods } from "../../constants/fetch";
import { fetchJSON, setFetchOpts } from "../../helpers/fetch";

export interface GetPageData {
  url: string;
  request?: any;
  params?: any;
  formValues?: Record<string, any>;
  pagination?: any;
  sorting?: {
    column: string;
    isAscending: boolean;
  };
}

export interface PageDataPagination {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export interface PageDataRequestInterface {
  data?: any;
  pagination?: PageDataPagination;
  error?: string;
  validationErrors?: Array<Array<string>>;
}

export const getPageData = async ({
  url,
  request,
  formValues,
  pagination,
  sorting,
  params,
}: GetPageData): Promise<PageDataRequestInterface> => {
  let queryParams = new URLSearchParams(params);

  if (formValues) {
    Object.keys(formValues).forEach((key: string) => {
      queryParams.set(key, formValues[key]);
    });
  }

  if (pagination) {
    queryParams.set("PageNumber", pagination.pageNumber);
    queryParams.set("PageSize", pagination.pageSize);
  }

  if (sorting) {
    queryParams.set(
      "SortBy",
      `${sorting.column} ${sorting.isAscending ? "asc" : "desc"}`
    );
  }

  const options = setFetchOpts({
    method: RequestMethods.GET,
    signal: request?.signal,
  });

  let queryString = queryParams.toString();
  url += `?${queryString ?? ""}`;

  const response = await fetchJSON(`${url}`, options, 10000);

  const { data, headers, error, errorData } = response;

  if (error) {
    return { error };
  }

  if (errorData) {
    const { errors: validationErrors } = errorData;

    if (validationErrors) {
      Object.keys(validationErrors).forEach((key: string) => {
        validationErrors[key] = validationErrors[key][0];
      });

      return { validationErrors };
    }
  }

  // if there is no response data that means the request returned a 204 indicating no content available.
  if (!response.data) {
    return { data: [] };
  }

  const paginationHeaders = headers.get("x-pagination");
  let paginationData = undefined;

  if (paginationHeaders) {
    paginationData = JSON.parse(paginationHeaders);
  }

  if (paginationData) {
    paginationData = {
      pageNumber: paginationData["CurrentPage"],
      pageSize: paginationData["PageSize"],
      totalPages: paginationData["TotalPages"],
      totalCount: paginationData["TotalCount"],
    };
  }

  return { data, pagination: paginationData };
};
