/**
 * Evaluates whether a property is defined and not null
 */
export const isDefined = (property: any): boolean => {
  return (
    typeof property !== "undefined" && property !== null && property !== ""
  );
};

export const orderColumns = (data: Array<any>, columnOrder: Array<string>) => {
  data?.forEach((row: Record<string, string>, index: number) => {
    const orderedRow: Record<string, string> = {};

    columnOrder.forEach((item: string) => {
      if (row.hasOwnProperty(item)) orderedRow[item] = row[item];
    });

    data[index] = orderedRow;
  });

  return data;
};

// Used in templates for actions buttons in tables that must select screen reader text from table data
export const textSelector =
  (key: string, fallback: string = "Item") =>
  (data: Record<string, any>) =>
    data[key] ?? fallback;
