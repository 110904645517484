import { FormContainer } from "../../containers/form";
import { Props } from "./interfaces";
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import { ErrorSummary } from "../forms/errorSummary";
import classNames from "classnames";
import { ReactComponent as DropdownIcon } from "../../img/svg-icons/chevron-down.svg";
import { mediaQueries } from "../../constants";

export const QueryBanner = ({
  formConfig,
  title,
  onFormSubmit,
  shouldRenderSubmit = true,
}: Props): JSX.Element => {
  const isTablet = useMediaQuery({
    query: mediaQueries.TABLET,
  });
  const wrapperRef: any = useRef(null);
  const [internalIsOpen, setInternalIsOpen] = useState<boolean>(true);

  const onToggle = () => {
    const isOpen: boolean = wrapperRef.current?.hasAttribute("open");
    setInternalIsOpen(isOpen);
  };

  useEffect(() => {
    if (isTablet) {
      setInternalIsOpen(true);
    } else {
      setInternalIsOpen(false);
    }
  }, [isTablet]);

  const generatedClasses = {
    summary: classNames("c-filters_summary u-box-border", {
      ["u-hidden"]: isTablet,
    }),
    summaryIcon: classNames("c-filters_summary-icon", {
      ["u-rotate-180 u--translate-y-2/4"]: internalIsOpen,
    }),
    submitButton: classNames("c-btn c-btn--teal u-h-12 u-mb-4", {
      ["u-hidden"]: !shouldRenderSubmit, // hiding button is easier than removing element entirely due to react child.map null issue.
    }),
  };

  return (
    <div className="c-filters u-p-4 tablet:u-p-0 tablet:u-py-4 u-bg-alto-grey u-to-viewport-edge-x">
      <h3 className="mobile:u-hidden tablet:u-block">{title}</h3>
      <details ref={wrapperRef} onToggle={onToggle} open={internalIsOpen}>
        <summary className={generatedClasses.summary}>
          {title}
          <DropdownIcon
            className={generatedClasses.summaryIcon}
            role="presentation"
            aria-hidden="true"
          />
        </summary>
        <div className="u-flex u-flex-col tablet:u-flex-row u-flex-wrap">
          {formConfig && onFormSubmit && (
            <FormContainer
              {...formConfig}
              classNames={{
                formWrapper:
                  "u-p-8 u-flex-col u-flex tablet:u-p-0 tablet:u-flex-wrap tablet:u-flex-row",
                fieldWrapper:
                  "u-flex  u-mb-4 u-flex-col tablet:u-items-center tablet:u-flex-row tablet:u-mr-4",
              }}
              onSubmitAction={onFormSubmit}
            >
              <button className={generatedClasses.submitButton} type="submit">
                Get Data
              </button>
              <ErrorSummary />
            </FormContainer>
          )}
        </div>
      </details>
    </div>
  );
};
