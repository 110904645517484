import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { renderers } from "../../constants";
import { FormFieldTypes, QueryResponse } from "../../types/form";
import { Children, cloneElement, useState } from "react";
import { ErrorSummary } from "../../components/forms/errorSummary";
import { FieldValidatorConfig, validator } from "../../validation/validator";
import { Submitting } from "../../components/modal-renderers/portalUsersTemplate";
import classNames from "classnames";

export interface Props {
  fields?: Array<FormFieldTypes>;
  onSubmitAction: (values: Record<string, string>) => Promise<QueryResponse>;
  fieldErrorsAction?: (
    errors: Record<string, string>,
    focusFieldsAction: Function
  ) => void;
  globalErrorAction?: (error?: string) => void;
  children?: React.ReactNode;
  classNames?: {
    formWrapper?: string;
    fieldWrapper?: string;
  };
  shouldRenderErrorsInline?: boolean;
  shouldRenderErrorSummary?: boolean;
}

export const FormContainer = ({
  fields,
  onSubmitAction,
  children,
  classNames: { formWrapper, fieldWrapper } = {},
  shouldRenderErrorsInline = false,
  shouldRenderErrorSummary = true,
}: Props): JSX.Element => {
  const [childRefs] = useState<Record<string, any>>({});

  const validate = (values: Record<string, string>) => {
    const validationRules: Record<string, Array<FieldValidatorConfig>> = {};
    fields?.forEach((field: FormFieldTypes) => {
      const { validators, id } = field;
      validationRules[id] = validators;
    });
    const validationErrors = validator(values, validationRules);
    const errors: Record<string, any> = {};
    Object.keys(validationErrors).forEach((key: string, index: number) => {
      const error: string = validationErrors[key]?.[0];
      if (error) {
        // focus on first error.
        if (index === 0) focusFieldAction(key);
        errors[key] = error;
      }
    });
    return errors;
  };

  const renderFields = (errorSummaryProps: Record<string, any> = {}) => {
    const { errors, focusFieldAction } = errorSummaryProps;
    return fields?.map((field: FormFieldTypes, index: number) => {
      const { id, renderer } = field;
      const FormControl = renderers[renderer];

      return (
        <Field
          name={id}
          {...field}
          key={index}
          render={(props: any) => {
            const fieldStyles = classNames(fieldWrapper, {
              ["u-m-0"]: props.input.type === "hidden",
            });

            return (
              <div className={fieldStyles}>
                <FormControl {...props} setFieldRef={setFieldRef} />
                {shouldRenderErrorsInline && errors?.[id] && (
                  <ErrorSummary
                    focusFieldAction={focusFieldAction}
                    errors={{ [id]: errors?.[id] }}
                  />
                )}
              </div>
            );
          }}
        />
      );
    });
  };

  const onSubmit = async (values: Record<string, string>) =>
    new Promise(async (resolve: any) => {
      const errors: Record<string, string> = validate(values);

      if (Object.keys(errors).length) {
        resolve(errors);
      } else {
        const response: QueryResponse = await onSubmitAction(values);

        const { hasSubmitErrors, hasSubmitError, submitError, submitErrors } =
          response;

        if (hasSubmitError) {
          resolve({
            [FORM_ERROR]: submitError,
          });
        } else if (hasSubmitErrors) {
          resolve(submitErrors);
        } else {
          resolve({});
        }
      }
    });

  const setFieldRef = (fieldName: string, ref: any) => {
    childRefs[fieldName] = ref;
  };

  const focusFieldAction = (fieldName: string) => {
    childRefs?.[fieldName]?.current.focus();
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({
        submitErrors,
        handleSubmit,
        hasSubmitErrors,
        submitError,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={formWrapper}>
              <>
                {shouldRenderErrorsInline
                  ? renderFields({
                      errors: submitErrors,
                      focusFieldAction: focusFieldAction,
                    })
                  : renderFields()}
                {Children.map(children, (child: any, index: number) => {
                  if (
                    child?.type?.name === ErrorSummary.name &&
                    shouldRenderErrorSummary
                  ) {
                    return cloneElement(child, {
                      errors: shouldRenderErrorsInline
                        ? undefined
                        : submitErrors,
                      focusFieldAction: focusFieldAction,
                      globalError: submitError,
                    });
                  } else if (child?.type?.name === Submitting.name) {
                    return cloneElement(child, {
                      submitting: submitting,
                    });
                  } else if (child !== null) {
                    return cloneElement(child);
                  }
                })}
              </>
            </div>
          </form>
        );
      }}
    />
  );
};
