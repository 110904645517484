import { isSameDay, parseISO, isAfter, isValid } from "date-fns";
import { messageConstants } from "../../constants";
import { isDefined } from "../../helpers/utils";
import { FieldValidatorConfig } from "../validator";

export interface DateRangeValidator extends FieldValidatorConfig {
  fieldUniqueIds: Array<string>;
}

export const dateRangeValidator = (
  value: any,
  formValues: Record<string, string>,
  validatorConfig: any
) => {
  const message: string = validatorConfig.message;
  const fieldUniqueIds: Array<string> = validatorConfig.fieldUniqueIds;

  if (!isDefined(message)) {
    throw new Error(messageConstants.validatorMisconfigured);
  }

  if (fieldUniqueIds && fieldUniqueIds.length && fieldUniqueIds.length == 2) {
    const startDateValue: string = formValues[fieldUniqueIds[0]];
    const endDateValue: string = formValues[fieldUniqueIds[1]];

    if (
      !startDateValue ||
      typeof startDateValue !== "string" ||
      !endDateValue ||
      typeof endDateValue !== "string"
    ) {
      return undefined;
    }

    const startDate: any = parseISO(startDateValue);
    const endDate: any = parseISO(endDateValue);

    if (isValid(startDate) && isValid(endDate)) {
      const isAfterDate: boolean = isAfter(startDate, endDate);
      return isAfterDate ? message : undefined;
    }

    return undefined;
  }

  return undefined;
};
