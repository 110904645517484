import { routeApis } from "../../constants/apis";
import { RequestMethods } from "../../constants/fetch";
import { UNEXPECTED_ERROR } from "../../constants/messages";
import { Routes } from "../../constants/routes";
import { ClientPortalPageRestrictionPermissions } from "../../constants/user";
import { fetchJSON, fetchWithTimeOut, setFetchOpts } from "../../helpers/fetch";
import { UserPageRestriction } from "../../types/global";
import { getPageData } from "../templates/page";

export const portalUsersLoader = async (props: any) => {
  return getPageData({
    url: routeApis[Routes.PORTAL_USERS].portalUsers,
    ...props,
  });
};

export const updatePortalUser = async ({
  formValues,
  pageRestrictions,
  item,
}: {
  formValues: Record<string, ClientPortalPageRestrictionPermissions>;
  pageRestrictions: Array<UserPageRestriction>;
  item: any;
}) => {
  pageRestrictions.forEach((pageRestriction: UserPageRestriction) => {
    const newRestrictionValue: ClientPortalPageRestrictionPermissions =
      formValues[`restriction:${pageRestriction.pageRestrictionId}`];
    if (newRestrictionValue)
      pageRestriction.restrictionPermission = newRestrictionValue;
  });

  const { azureUserId } = item;

  const body = {
    userId: azureUserId,
    pageRestrictions,
  };

  const fetchOptions = setFetchOpts({
    method: RequestMethods.PUT,
    body,
  });

  try {
    const response = await fetchWithTimeOut(
      routeApis[Routes.PORTAL_USERS].userPageRestrictions,
      fetchOptions,
      10000
    );

    if (!response.ok) {
      return { error: UNEXPECTED_ERROR };
    } else {
      return { error: undefined };
    }
  } catch (error) {
    return { error: UNEXPECTED_ERROR };
  }
};

export const getPortalUserNotes = async ({ userId }: { userId: string }) => {
  const fetchOptions = setFetchOpts({
    method: RequestMethods.GET,
  });

  try {
    const response = await fetchJSON(
      `${
        routeApis[Routes.PENDING_EMAIL_CHANGES].getPendingItems
      }/Notes/${userId}`,
      fetchOptions,
      10000
    );

    const { error, data } = response;

    if (error) {
      return { error: UNEXPECTED_ERROR };
    } else {
      return { data };
    }
  } catch (error) {
    return { error: UNEXPECTED_ERROR };
  }
};

export const reactivatePortalUser = async ({
  azureUserId,
}: {
  azureUserId: string;
}) => {
  const fetchOptions = setFetchOpts({
    method: RequestMethods.POST,
    body: {
      azureUserId,
    },
  });

  try {
    const response = await fetchJSON(
      `${routeApis[Routes.PORTAL_USERS].portalUsers}/Reactivate`,
      fetchOptions,
      10000
    );

    const { error, data } = response;

    if (error) {
      return { error: UNEXPECTED_ERROR };
    } else {
      return { data };
    }
  } catch (error) {
    return { error: UNEXPECTED_ERROR };
  }
};
