import { Props } from "./interfaces";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export const Input = ({
  id,
  input,
  meta,
  placeholder = "Enter text",
  isLabelHidden,
  label,
  setFieldRef,
  classes,
}: Props): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    setFieldRef(id, ref);
  }, []);

  const generatedClasses = {
    label: classNames("c-label tablet:u-mr-4", classes?.label, {
      ["u-sr-only"]: isLabelHidden,
    }),
  };

  return (
    <div className="u-flex u-flex-wrap">
      <label htmlFor={id} className={generatedClasses.label}>
        {label}
      </label>
      <input
        ref={ref}
        type="text"
        placeholder={placeholder}
        id={id}
        {...input}
        className="c-input-text u-inline-block u-font-family-bold"
      />
    </div>
  );
};
