export interface Props {
  errors?: Record<string, string>;
  globalError?: string;
  focusFieldAction?: any;
  titleText?: string;
}

export const ErrorSummary = ({
  errors = {},
  focusFieldAction,
  globalError,
  titleText,
}: Props): JSX.Element => {
  /**
   * focus field by field name
   */
  const onClick = (fieldName: string) => (event: any) => {
    event.preventDefault();
    focusFieldAction?.(fieldName);
  };

  const hasErrors: boolean =
    Boolean(globalError) || Object.keys(errors).length > 0;

  return (
    <div role="alert" className="u-w-full u-overflow-wrap">
      {hasErrors && (
        <>
          {titleText && <h3>{titleText}</h3>}
          <ul className="c-error-summary">
            {globalError && (
              <li className="c-error-summary_item">{globalError}</li>
            )}
            {Object.keys(errors).map((key: string, index: number) => {
              return (
                <li className="c-error-summary_item" key={index}>
                  <a
                    className="c-error-summary_link"
                    onClick={onClick(key)}
                    href="#"
                  >
                    {errors[key]}
                  </a>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};
