export const Error = ({ message }: { message: string }) => {
  return (
    <div className="u-w-full u-flex u-items-center u-justify-center u-flex-col u-h-full u-fixed">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{message}</i>
      </p>
    </div>
  );
};
