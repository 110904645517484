import { messageConstants } from "../../constants";
import { isDefined } from "../../helpers/utils";
import { FieldValidatorConfig } from "../validator";

export interface RequiredValidator extends FieldValidatorConfig {}

export const requiredValidator = (
  value: any,
  formValues: Record<string, string>,
  validatorConfig: any
) => {
  const { message } = validatorConfig;

  if (!isDefined(message)) {
    throw new Error(messageConstants.validatorMisconfigured);
  }

  if (value) {
    if (Array.isArray(value) && value.length === 0) {
      return message;
    } else if (typeof value === "string") {
      if (value.trim && value.trim() === "") {
        return message;
      }
    }

    return undefined;
  }

  return message;
};
