import classNames from "classnames";
import { NavItem } from "../nav-item";
import { Props } from "./interface";

export const Nav = ({ className, items, closeNav }: Props): JSX.Element => {
  const generatedClasses: Record<string, string> = {
    wrapper: classNames(className, "c-main-nav"),
    container: classNames("c-main-nav_container"),
    nav: classNames("c-main-nav_nav"),
    list: classNames("c-main-nav_list"),
  };

  return (
    <div className={generatedClasses.wrapper} id="main-menu">
      <div className={generatedClasses.container}>
        <nav className={generatedClasses.nav}>
          <ul className={generatedClasses.list}>
            {items.map((item, index) => (
              <NavItem {...item} key={index} closeNav={closeNav} />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
