import { RequestMethods } from "../constants/fetch";
import { UNEXPECTED_ERROR } from "../constants/messages";
import { FetchOptions, FetchResponse } from "../types/fetch";
import { isDefined } from "./utils";

/**
 * Generic wrapper for Fetch which will reject on timeOut
 */
export const fetchWithTimeOut = (
  url: string,
  options: FetchOptions,
  timeOut: number
): Promise<any> => {
  return Promise.race([
    fetch(
      `${
        process.env.NODE_ENV === "test" ? "https://localhost:3000" : ""
      }${url}`,
      options as any
    ).then((response: any) => {
      return response;
    }),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("The request timed out")), timeOut)
    ),
  ]);
};

/**
 * Custom wrapper for Fetch to abstract error handling and JSON parsing
 */
export const fetchJSON = async (
  url: string,
  options: FetchOptions,
  timeOut: number
): Promise<FetchResponse> => {
  try {
    const response: Response = await fetchWithTimeOut(url, options, timeOut);

    if (!response.ok) {
      if (response.status === 400) {
        const data = await response.json();

        if (typeof data === "string") {
          return {
            error: data,
          };
        }

        return {
          errorData: data,
        };
      } else {
        return { error: UNEXPECTED_ERROR };
      }
    } else {
      const dataType = response.headers.get("Content-Type");
      const isJson = dataType?.includes("application/json");

      // if response is 200 and the data type is json we can read the response
      if (response.status === 200 && isJson) {
        const data = await response.json();
        const headers = response.headers;

        return {
          data,
          headers,
        };
      } else return {};
      // else return empty response which means success with no data (e.g. 204)
    }
  } catch (error) {
    return { error: UNEXPECTED_ERROR };
  }
};

/**
 * Returns a default options object to use for requests
 */
export const setFetchOpts = ({
  method,
  headers,
  isMultiPartForm,
  body,
  signal,
}: {
  method: RequestMethods;
  headers?: Headers;
  isMultiPartForm?: boolean;
  body?: any;
  signal?: any;
}): FetchOptions => {
  const headersToUse: Headers = new Headers({
    Accept: "application/json",
  });

  if (!isMultiPartForm) {
    headersToUse.set("Content-Type", "application/json");
  }

  if (headers) {
    for (const key in headers) {
      headersToUse.set(key, headers.get(key) || "");
    }
  }

  const fetchOpts: FetchOptions = {
    method: method,
    credentials: "include",
    headers: headersToUse,
    signal,
  };

  if (
    (isDefined(body) && method === RequestMethods.POST) ||
    method === RequestMethods.PUT ||
    method === RequestMethods.PATCH
  ) {
    fetchOpts.body = isMultiPartForm ? body : JSON.stringify(body);
  }

  return fetchOpts;
};

/**
 * Generic timer to add timeout functionality to fetch requests
 */
export const timer = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      return resolve({});
    }, delay);
  });

export const activateBasicFetch = async (
  url: string,
  fetchOptions: FetchOptions
): Promise<FetchResponse> => {
  const response = await fetchJSON(url, fetchOptions, 10000);
  const { error, errorData } = response;

  if (error) {
    //i.e. an unknown error returned
    return {
      error,
    };
  }

  // if error data exists formatting on that data is required
  if (errorData) {
    const { errors: validationErrors } = errorData;

    if (validationErrors) {
      Object.keys(validationErrors).forEach((key: string) => {
        validationErrors[key] = validationErrors[key][0];
      });

      return { errorData: validationErrors };
    }
  }

  return { error: undefined };
};
