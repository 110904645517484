import { Props } from "./interfaces";
import { ReactComponent as SearchSvg } from "../../../img/svg-icons/search.svg";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export const Search = ({
  id,
  input,
  meta,
  placeholder = "Enter text",
  isLabelHidden,
  label,
  setFieldRef,
}: Props): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    setFieldRef(id, ref);
  }, []);

  const generatedClasses = {
    label: classNames("c-label tablet:u-mr-4", {
      ["u-sr-only"]: isLabelHidden,
    }),
  };

  return (
    <div className="u-flex">
      <label htmlFor={id} className={generatedClasses.label}>
        {label}
      </label>
      <input
        ref={ref}
        placeholder={placeholder}
        id={id}
        {...input}
        type="search"
        className="c-input-text u-inline-block u-font-family-bold tablet:u-w-64"
      />
      <button
        aria-label="Select to search"
        type="submit"
        className="c-btn c-btn--teal u-m-0 u-px-4 u-h-12 u-whitespace-nowrap u-flex u-justify-center u-items-center"
      >
        <SearchSvg
          role="presentation"
          aria-hidden="true"
          className="c-svg-icon u-fill-white u-w-8 u-h-8"
        />
      </button>
    </div>
  );
};
