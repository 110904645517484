import classNames from "classnames";
import { FormContainer } from "../../../containers/form";
import { FormConfig } from "../../../types/form";
import { ComponentAction } from "../../../types/global";
import { ActionButton } from "../../actionButton";
import { ErrorSummary } from "../../forms/errorSummary";
import { Props } from "../interface";
import { Submitting } from "../portalUsersTemplate";

export const SettingsTemplateInnerDialog = ({
  data,
  actions,
  title,
  warningText,
  onFormSubmit,
  generateFormConfig,
  hiddenFields = ["id", "contentId", "cpdApplicable"],
}: Props): JSX.Element => {
  const item: Record<string, any> = Object.assign({}, data);

  hiddenFields?.forEach((hiddenField: string) => {
    if (item.hasOwnProperty(hiddenField)) {
      delete item[hiddenField];
    }
  });

  const generatedClasses: Record<string, string> = {
    wrapper: classNames("u-p-3"),
    title: classNames("u-p-0 u-m-0"),
    buttonWrapper: classNames("u-flex u-justify-end u-mb-6"),
    button: classNames("u-rounded-none u-w-fit"),
    warningText: classNames("u-text-celery u-text-wrap"),
    formWrapper: classNames("c-form u-my-4"),
    fieldWrapper: classNames(
      "o-form-item u-flex u-flex-col u-flex-wrap u-gap-2"
    ),
    formLabel: classNames("o-form-label u-font-bold u-text-lg"),
    inputText: classNames("c-input-text  u-font-family-bold"),
    buttons: classNames("u-flex u-mx-0 u-gap-2 u-flex-wrap u-mt-8 u-w-full", {
      ["u-justify-between"]: actions ? actions?.length > 1 : false,
      ["u-justify-center"]: actions ? actions?.length <= 1 : false,
    }),
  };

  const formConfig: FormConfig = generateFormConfig(item);

  return (
    <div className={generatedClasses.wrapper}>
      <h2 className={generatedClasses.text}>{title}</h2>
      <span className={generatedClasses.warningText}>{warningText}</span>
      {formConfig && (
        <FormContainer
          {...formConfig}
          classNames={{
            formWrapper: generatedClasses.formWrapper,
            fieldWrapper: generatedClasses.fieldWrapper,
          }}
          onSubmitAction={onFormSubmit}
          shouldRenderErrorsInline={true}
        >
          {/* passed props via React.CloneElement on children of FormContainer */}
          <Submitting />
          <ErrorSummary />

          <div className={generatedClasses.buttons}>
            {/* TODO: actions require confirmation stage */}
            {actions?.map(
              ({ text, callback, props }: ComponentAction, index) => (
                <ActionButton
                  className={generatedClasses.button}
                  callback={() => callback?.()}
                  key={index}
                  {...props}
                >
                  <>{text}</>
                </ActionButton>
              )
            )}
          </div>
        </FormContainer>
      )}
    </div>
  );
};
