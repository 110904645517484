import { useRouteError } from "react-router-dom";
import { Error } from "../../components/error";

export default function ErrorPage() {
  const error: any = useRouteError();

  const message: string = error?.status
    ? `${error.status}: ${error.statusText}`
    : error.message;

  return <Error message={message} />;
}
