export const ActionButton = ({
  children,
  callback,
  className,
  ...props
}: {
  children: React.ReactNode;
  callback?: Function;
  className?: string;
}): JSX.Element => {
  return (
    <button
      {...props}
      className={`u-bg-transparent u-text-black u-border-2 u-border-teal-green u-border-solid u-rounded u-cursor-pointer u-px-4 u-w-full ${
        className ?? ""
      }`}
      onClick={(): void => callback?.()}
    >
      <>{children}</>
    </button>
  );
};
