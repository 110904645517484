import { useState } from "react";
import { Dialog } from "../../../components/dialog";
import { SettingsTemplateInnerDialog } from "../../../components/modal-renderers/settingsTemplate";
import { TemplateActionTypes } from "../../../types/global";
import { ActionButton } from "../../../components/actionButton";

import {
  addSettingData,
  deleteSettingItem,
  updateSettingItem,
} from "../../../loaders/templates/settingsTemplate";
import { DeleteItem } from "../../../components/delete-item";
import { Props } from "./interface";
import { FetchResponse } from "../../../types/fetch";
import { tableHeaders as tableHeaderMap } from "../../../constants/tableHeaders";
import { getPageData as getPageDataDefault } from "../../../loaders/templates/page";
import { orderColumns, textSelector } from "../../../helpers/utils";
import { RequestMethods } from "../../../constants/fetch";
import { AdvancedTable } from "../../../components/advancedTable";
import { Success } from "../../../components/success";

export const SettingsPageTemplate = ({
  InnerDialogRenderer = SettingsTemplateInnerDialog,
  pageTitleText,
  modalText,
  noResultsText,
  cancelText,
  saveText,
  tableCaptionText,
  tableId = "UserAdminAppTable",
  tableData: tableDataProps,
  hiddenColumns: hiddenColumnsProps,
  apis,
  getPageData = getPageDataDefault,
  editWarningText,
  generateFormConfig,
  addNewItemText,
  newItemFormData,
  shouldCloseDialogOnEsc = true,
  columnHeaderTextMap = tableHeaderMap,
  columnOrder,
  viewText,
  dataItemKey,
  error: errorProp,
}: Props): JSX.Element => {
  const [dialogItem, setDialogItem] = useState<Record<string, any>>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [shouldDelete, setShouldDelete] = useState<boolean>(false);
  const [modalSubmitType, setModalSubmitType] = useState<RequestMethods>();

  const [tableDataState, setTableDataState] =
    useState<Array<Record<string, any>>>(tableDataProps);
  const [pageDataRequestError, setError] = useState<string | undefined>(
    errorProp
  );
  const [modalError, setModalError] = useState<string | undefined>("");
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<any>(false);
  const hasTableData = tableDataState?.length > 0;

  const actions: TemplateActionTypes = {
    table: [
      {
        text: viewText,
        srText: textSelector(dataItemKey),
        callback: (data: any): void => {
          setDialogItem(data);
          setIsDialogOpen(true);
          setModalSubmitType(RequestMethods.PUT);
        },
      },
      {
        text: "Delete",
        srText: textSelector(dataItemKey),
        callback: (data: any): void => {
          setDialogItem(data);
          setShouldDelete(true);
          setIsDialogOpen(true);
        },
      },
    ],
    modal: [
      {
        text: cancelText,
        callback: (): void => {
          handleCloseDialog();
        },
        props: {
          type: "button",
        },
      },
      {
        text: saveText,
        props: {
          type: "submit",
        },
      },
    ],
  };

  const handleCloseDialog = () => {
    if (!shouldCloseDialogOnEsc) {
      return;
    }

    setIsUpdateSuccess(false);
    setIsDialogOpen(false);
    setDialogItem({});
    setShouldDelete(false);
    setError(undefined);
    setModalError(undefined);
  };

  const onGet = () =>
    new Promise(async (resolve: any, reject: any) => {
      setModalError(undefined);

      const response = await getPageData({
        url: apis.get,
      });

      let { data: dataResponse, error } = response;

      if (error) {
        setError(error);
      } else {
        dataResponse = orderColumns(dataResponse, columnOrder);
        setTableDataState(dataResponse);
        // resolve to satisfy final form
        return resolve({});
      }
    });

  const onCreate = (formValues: Record<string, string>) =>
    new Promise(async (resolve: any, reject: any) => {
      setModalError(undefined);
      setIsUpdateSuccess(false);

      const response: FetchResponse = await addSettingData({
        url: apis.update || "",
        item: formValues,
      });

      const { error, errorData: validationErrors }: FetchResponse = response;

      if (error) {
        setModalError(error);
        resolve({
          hasSubmitError: true,
          submitError: error,
        });
      } else if (validationErrors) {
        setModalError(error);
        resolve({
          hasSubmitErrors: true,
          submitErrors: validationErrors,
        });
      } else {
        onGet();
        setIsUpdateSuccess(true);
        resolve({});
      }
    });

  const onUpdate = (formValues: Record<string, string>) =>
    new Promise(async (resolve: any, reject: any) => {
      setModalError(undefined);
      setIsUpdateSuccess(false);

      const { contentId, id } = dialogItem;
      const response = await updateSettingItem({
        url: apis.update || "",
        item: {
          ...formValues,
          contentId,
          id,
        },
      });

      const { error, errorData: validationErrors }: FetchResponse = response;

      if (error) {
        setModalError(error);
        resolve({
          hasSubmitError: true,
          submitError: error,
        });
      } else if (validationErrors) {
        setModalError(error);
        resolve({
          hasSubmitErrors: true,
          submitErrors: validationErrors,
        });
      } else {
        onGet();
        setIsUpdateSuccess(true);
        resolve({});
      }
    });

  const onDelete = () =>
    new Promise(async (resolve: any, reject: any) => {
      const response: FetchResponse = await deleteSettingItem({
        url: `${apis.update}/${dialogItem?.id || dialogItem?.contentId}`!,
      });

      const { error, errorData: validationErrors }: FetchResponse = response;

      if (error) {
        setModalError(error);
        resolve({});
      } else if (validationErrors) {
        setModalError(error);
        resolve({
          hasSubmitErrors: true,
          submitErrors: validationErrors,
        });
      } else {
        onGet();
        setIsUpdateSuccess(true);
        resolve({});
      }
    });

  const onFormSubmit = (formValues: Record<string, string>) => {
    if (modalSubmitType === RequestMethods.POST) {
      return onCreate(formValues);
    }

    return onUpdate(formValues);
  };

  return (
    <>
      <div className="u-bg-burnham-green u-text-center u-text-white u-m-0 u-p-2 u-to-viewport-edge-x">
        <h2>{pageTitleText}</h2>
      </div>
      <div className="u-flex u-flex-col u-justify-center u-my-5 u-w-full u-overflow-x-auto">
        {pageDataRequestError ? (
          <>{pageDataRequestError}</>
        ) : !hasTableData ? (
          <>{noResultsText}</>
        ) : (
          <AdvancedTable
            data={tableDataState}
            caption={tableCaptionText}
            id={tableId}
            hiddenColumns={hiddenColumnsProps}
            columnHeaderTextMap={columnHeaderTextMap}
            actions={actions.table}
          />
        )}
      </div>
      <div className="u-flex u-justify-center u-relative u-m-4">
        <ActionButton
          className="u-w-fit"
          callback={() => {
            setDialogItem(newItemFormData);
            setModalSubmitType(RequestMethods.POST);
            setIsDialogOpen(true);
          }}
        >
          {addNewItemText}
        </ActionButton>
      </div>
      <Dialog
        id={dialogItem?.id}
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        {isUpdateSuccess ? (
          <Success />
        ) : shouldDelete ? (
          <DeleteItem
            item={dialogItem}
            rejectAction={handleCloseDialog}
            acceptAction={onDelete}
            error={modalError}
          />
        ) : (
          <InnerDialogRenderer
            title={modalText}
            warningText={editWarningText}
            actions={actions.modal}
            data={dialogItem}
            store={tableDataState}
            onFormSubmit={onFormSubmit}
            error={modalError}
            generateFormConfig={generateFormConfig}
          />
        )}
      </Dialog>
    </>
  );
};
