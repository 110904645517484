import { QueryBanner } from "../../../components/queryBanner/queryBanner";
import { useContext, useState } from "react";
import { Dialog } from "../../../components/dialog";
import { Pagination } from "../../../components/pagination";
import { EmailTemplateInnerDialog } from "../../../components/modal-renderers/emailTemplate";
import { TemplateActionTypes } from "../../../types/global";
import { AppContext, AppContextType } from "../../../constants/context";
import { emailPagesForm } from "../../../formConfig/queryBanner/emailPages";
import { usePageDataQuery } from "../../../hooks/usePageDataQuery";
import { Props } from "./interface";
import { textSelector } from "../../../helpers/utils";
import { AdvancedTable } from "../../../components/advancedTable";

export const EmailPageTemplate = ({
  InnerDialogRenderer = EmailTemplateInnerDialog,
  pageTitleText,
  modalText,
  noResultsText,
  queryBannerText = "Search emails or filter by:",
  tableId = "UserAdminAppTable",
  pagination: paginationProps,
  tableData: tableDataProps,
  sortableColumns: sortableColumnsProps,
  hiddenColumns: hiddenColumnsProps,
  apis,
  tableCaptionText,
  columnOrder,
  viewText,
  dataItemKey,
  error: errorProp,
}: Props): JSX.Element => {
  const [dialogItem, setDialogItem] = useState<Record<string, any>>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [modalError, setModalError] = useState<any>();
  const appContext = useContext<AppContextType>(AppContext);

  const {
    pageDataRequestError,
    activeSort,
    paginationState,
    tableDataState,
    onFormSubmit,
    onPageChange,
    onSort,
    setError,
  } = usePageDataQuery(
    {
      formValues,
      tableDataProps,
      paginationProps,
      initialError: errorProp,
      apis,
      columnOrder,
    },
    { setFormValues }
  );

  const { totalCount, totalPages, pageNumber, pageSize } =
    paginationState ?? {};
  const hasTableData = tableDataState?.length > 0;
  const { userRoles } = appContext;

  const actions: TemplateActionTypes = {
    table: [
      {
        text: viewText,
        srText: textSelector(dataItemKey),
        callback: (data: any): void => {
          setDialogItem({ ...data });
          setIsDialogOpen(true);
        },
      },
    ],
    modal: [
      {
        text: "Close",
        callback: () => {
          handleCloseDialog();
        },
      },
    ],
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setDialogItem({});
    setError(null);
    setModalError(null);
  };

  return (
    <>
      <div className="u-bg-burnham-green u-text-center u-text-white u-m-0 u-p-2 u-to-viewport-edge-x">
        <h2>{pageTitleText}</h2>
      </div>
      <QueryBanner
        title={queryBannerText}
        formConfig={emailPagesForm(userRoles)}
        onFormSubmit={onFormSubmit}
      />
      <div className="u-flex u-flex-col u-justify-center u-my-5 u-w-full u-overflow-x-auto">
        {pageDataRequestError ? (
          <>{pageDataRequestError}</>
        ) : !hasTableData ? (
          <>{noResultsText}</>
        ) : (
          <AdvancedTable
            data={tableDataState}
            caption={tableCaptionText}
            id={tableId}
            sortableColumns={sortableColumnsProps}
            hiddenColumns={hiddenColumnsProps}
            sortFunction={onSort}
            activeSort={activeSort}
            actions={actions.table}
          />
        )}
      </div>
      {hasTableData && (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={totalCount}
          totalPages={totalPages}
          setPageNumberAction={onPageChange}
          numberOfResults={tableDataState.length}
          ariaProps={{
            controls: tableId,
          }}
        />
      )}
      <Dialog
        id={dialogItem?.id}
        isOpen={isDialogOpen}
        handleCloseDialog={() => setIsDialogOpen(false)}
      >
        <InnerDialogRenderer
          title={modalText}
          actions={actions?.modal}
          data={dialogItem}
          error={modalError}
        />
      </Dialog>
    </>
  );
};
