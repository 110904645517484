import { QueryBanner } from "../../../components/queryBanner/queryBanner";
import { pendingPagesForm } from "../../../formConfig/queryBanner/pendingPages";
import { useContext, useState } from "react";
import { Dialog } from "../../../components/dialog";
import { PendingInnerTemplateDialog } from "../../../components/modal-renderers/pendingTemplate";
import { Pagination } from "../../../components/pagination";
import {
  rejectPendingItem,
  reSubmitPendingItem,
} from "../../../loaders/templates/pendingTemplate";
import { AppContext, AppContextType } from "../../../constants/context";
import { TemplateActionTypes } from "../../../types/global";
import { tableHeaders } from "../../../constants/tableHeaders";
import { usePageDataQuery } from "../../../hooks/usePageDataQuery";
import { textSelector } from "../../../helpers/utils";
import { Props, RequestHandler } from "./interface";
import { AdvancedTable } from "../../../components/advancedTable";
import { UNEXPECTED_ERROR } from "../../../constants/messages";
import { Success } from "../../../components/success";
import { ConfirmHandler } from "../../../components/confirm";
import { Loading } from "../../../components/loading";
import { FieldRenderers, FormConfig } from "../../../types/form";
import { FieldValidatorTypes } from "../../../validation/validator";

export const PendingPagesTemplate = ({
  InnerDialogRenderer = PendingInnerTemplateDialog,
  rejectHandler = rejectPendingItem,
  resubmitHandler = reSubmitPendingItem,
  rejectSuccessMessage,
  resubmitSuccessMessage,
  pageTitleText,
  modalText,
  noResultsText,
  resubmitText,
  rejectText,
  queryBannerText,
  viewText = "View",
  dataItemKey,
  tableCaptionText,
  tableId = "UserAdminAppTable",
  pagination: paginationProps,
  tableData: tableDataProps,
  sortableColumns: sortableColumnsProps,
  hiddenColumns: hiddenColumnsProps,
  apis,
  columnOrder,
  columnHeaderTextMap = tableHeaders,
  hiddenModalFields,
  error: errorProp,
  confirmHandlerText,
  shouldIncludeRejectionNote = false,
}: Props): JSX.Element => {
  const [dialogItem, setDialogItem] = useState<Record<string, any>>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [modalError, setModalError] = useState<any>();
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejectData, setRejectData] = useState<Record<string, any>>({});
  const appContext = useContext<AppContextType>(AppContext);

  const {
    pageDataRequestError,
    activeSort,
    paginationState,
    tableDataState,
    onFormSubmit,
    onGet,
    onPageChange,
    onSort,
  } = usePageDataQuery(
    {
      formValues,
      tableDataProps,
      paginationProps,
      initialError: errorProp,
      apis,
      columnOrder,
    },
    { setFormValues }
  );
  const { totalCount, totalPages, pageNumber, pageSize } =
    paginationState ?? {};
  const hasTableData = tableDataState?.length > 0;
  const { userRoles } = appContext;

  const formConfig: FormConfig = {
    fields: [
      {
        id: "note",
        renderer: FieldRenderers.TEXT_AREA,
        label: "Reason",
        isLabelHidden: false,
        classes: {
          label: "o-form-label u-font-bold u-text-lg",
        },
        validators: [
          {
            type: FieldValidatorTypes.Required,
            message: `Reason is required`,
          },
        ],
      },
    ],
  };

  // Run basic fetch to reject or resubmit data
  const requestHandlerCreator =
    (
      fetchHandler: RequestHandler,
      url: string,
      successMessage: string = "Success!"
    ) =>
    async (data: any): Promise<void> => {
      setModalError(undefined);
      setIsUpdateSuccess(false);
      setSuccessMessage("");
      setIsLoading(true);

      const { error, errorData: validationErrors } = await fetchHandler(
        url,
        data
      );

      setIsLoading(false);

      if (error) {
        // deal with error
        setModalError(error);
      } else if (validationErrors) {
        // for a 400 on this request only show generic error not validation errors as the user has no control over data sent to server.
        setModalError(UNEXPECTED_ERROR);
      } else {
        setIsUpdateSuccess(true);
        setSuccessMessage(successMessage);

        onGet({
          formValues,
          pagination: paginationState,
          sorting: activeSort ? activeSort : undefined,
        });
      }
    };

  // create actions to run on reject or resubmit
  const rejectHandlerAction: (data: Record<string, any>) => Promise<void> =
    requestHandlerCreator(rejectHandler, apis.reject, rejectSuccessMessage);
  const resubmitHandlerAction: (data: Record<string, any>) => Promise<void> =
    requestHandlerCreator(resubmitHandler, apis.accept, resubmitSuccessMessage);

  // create actions run on accept/cancel when rejecting
  const confirmDialogueCancelHandler: Function = () =>
    setShouldShowConfirm(false);
  const confirmDialogueAcceptHandler: Function = (
    formValues?: Record<string, any>
  ) => {
    setShouldShowConfirm(false);
    rejectHandlerAction({ ...rejectData, ...(formValues ? formValues : {}) });
  };

  const onRejectionNoteSubmit = async (formValues: Record<string, string>) => {
    return new Promise(async (resolve) => {
      resolve({});
      confirmDialogueAcceptHandler(formValues);
    });
  };

  const actions: TemplateActionTypes = {
    table: [
      {
        text: viewText,
        srText: textSelector(dataItemKey),
        callback: (data: any): void => {
          setDialogItem(data);
          setIsDialogOpen(true);
        },
      },
    ],
    modal: [
      {
        text: rejectText,
        callback: (data) => {
          setShouldShowConfirm(true);
          setRejectData(data);
        },
      },
      {
        text: resubmitText,
        callback: resubmitHandlerAction,
      },
    ],
  };

  const handleCloseDialog = (): void => {
    setModalError(undefined);
    setIsDialogOpen(false);
    setIsUpdateSuccess(false);
    setSuccessMessage("");
    setShouldShowConfirm(false);
    setDialogItem({});
  };

  const renderDialogContent: () => JSX.Element = () => {
    if (isLoading) {
      return <Loading isActive={isLoading} />;
    } else if (shouldShowConfirm) {
      const form = shouldIncludeRejectionNote
        ? {
            formConfig,
            onFormSubmit: onRejectionNoteSubmit,
          }
        : {};

      return (
        <ConfirmHandler
          confirmationText={confirmHandlerText}
          acceptAction={confirmDialogueAcceptHandler}
          cancelAction={confirmDialogueCancelHandler}
          {...form}
        />
      );
    } else if (isUpdateSuccess) {
      return <Success message={successMessage} />;
    }

    return (
      <InnerDialogRenderer
        title={modalText}
        actions={actions.modal}
        data={dialogItem}
        error={modalError}
        columnHeaderTextmap={columnHeaderTextMap}
        hiddenFields={hiddenModalFields}
      />
    );
  };

  return (
    <>
      <div className="u-bg-burnham-green u-text-center u-text-white u-m-0 u-p-2 u-to-viewport-edge-x">
        <h2>{pageTitleText}</h2>
      </div>
      <QueryBanner
        title={queryBannerText}
        formConfig={pendingPagesForm(userRoles)}
        onFormSubmit={onFormSubmit}
      />
      <div className="u-flex u-flex-col u-justify-center u-my-5 u-w-full u-overflow-x-auto">
        {pageDataRequestError ? (
          <>{pageDataRequestError}</>
        ) : !hasTableData ? (
          <>{noResultsText}</>
        ) : (
          <AdvancedTable
            data={tableDataState}
            caption={tableCaptionText}
            id={tableId}
            sortableColumns={sortableColumnsProps}
            hiddenColumns={hiddenColumnsProps}
            sortFunction={onSort}
            activeSort={activeSort}
            columnHeaderTextMap={columnHeaderTextMap}
            actions={actions.table}
          />
        )}
      </div>
      {hasTableData && (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={totalCount}
          totalPages={totalPages}
          setPageNumberAction={onPageChange}
          numberOfResults={tableDataState.length}
          ariaProps={{
            controls: tableId,
          }}
        />
      )}
      <Dialog
        id={dialogItem?.id}
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        {renderDialogContent()}
      </Dialog>
    </>
  );
};
