export const enum Routes {
  HOME,
  PENDING_REGISTRATIONS,
  PENDING_RECORDS,
  PENDING_REQUESTS,
  PENDING_EMAIL_CHANGES,
  USER_MANAGEMENT,
  PORTAL_USERS,
  EMAIL_LOG,
  SETTINGS,
  CLIENT_PORTAL_PAGE_RESTRICTIONS,
  CLIENT_PORTAL_SALESFORCE_CAMPAIGNS,
  ADMIN,
  REPORTS,
}

export const routePaths: Record<Routes, string> = {
  [Routes.HOME]: "/",
  [Routes.PENDING_REGISTRATIONS]: "/pending-registrations",
  [Routes.PENDING_RECORDS]: "/pending-records",
  [Routes.PENDING_REQUESTS]: "/pending-requests",
  [Routes.PENDING_EMAIL_CHANGES]: "/pending-email-changes",
  [Routes.USER_MANAGEMENT]: "/user-management",
  [Routes.PORTAL_USERS]: "/portal-users",
  [Routes.EMAIL_LOG]: "/email-log",
  [Routes.SETTINGS]: "/settings",
  [Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS]:
    "/settings/client-portal-page-restrictions",
  [Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]:
    "/settings/client-portal-campaigns",
  [Routes.ADMIN]: "/admin",
  [Routes.REPORTS]: "/reports",
};

export const routeNames: Record<Routes, string> = {
  [Routes.HOME]: "",
  [Routes.PENDING_REGISTRATIONS]: "Pending Registrations",
  [Routes.PENDING_RECORDS]: "Pending Records",
  [Routes.PENDING_REQUESTS]: "Pending requests",
  [Routes.PENDING_EMAIL_CHANGES]: "Pending email changes",
  [Routes.USER_MANAGEMENT]: "user management",
  [Routes.PORTAL_USERS]: "Portal Users",
  [Routes.EMAIL_LOG]: "Email Log",
  [Routes.SETTINGS]: "Settings",
  [Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS]: "Client portal page restrictions",
  [Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]:
    "Client portal salesforce campaigns",
  [Routes.ADMIN]: "Admin",
  [Routes.REPORTS]: "Reports",
};
