import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import {
  dataKeys,
  pendingRegistrationsTableHeaders,
} from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import {
  rejectPendingRegistration,
  reSubmitPendingRegistration,
} from "../../../loaders/pages/pendingRegistrations";
import { PendingPagesTemplate } from "../../templates/pendingPageTemplate";

export const PendingRegistrations = () => {
  let { data, pagination, error }: any = useLoaderData();

  const { hiddenColumns, order, sortable, hiddenFields } =
    columnConfig.pendingRegistrationsColumnOrder;
  data = orderColumns(data, order);

  return (
    <PendingPagesTemplate
      tableData={data}
      hiddenColumns={hiddenColumns}
      columnOrder={order}
      sortableColumns={sortable}
      hiddenModalFields={hiddenFields}
      pagination={pagination}
      pageTitleText="Pending Registrations"
      modalText="Pending Registration"
      noResultsText="There are no pending registrations whose email address contains your search term. Please search again"
      resubmitText="Resubmit registration"
      rejectText="Reject registration"
      tableCaptionText="Pending registrations"
      queryBannerText="Search registrations or filter by:"
      viewText="View"
      confirmHandlerText="Are you sure you would like to reject this registration?"
      dataItemKey={dataKeys.email}
      apis={{
        get: routeApis[Routes.PENDING_REGISTRATIONS].getPendingItems,
        reject: routeApis[Routes.PENDING_REGISTRATIONS].rejectPendingItem,
        accept: routeApis[Routes.PENDING_REGISTRATIONS].resubmitPendingItem,
      }}
      resubmitHandler={reSubmitPendingRegistration}
      rejectHandler={rejectPendingRegistration}
      resubmitSuccessMessage={
        "Request resubmitted - please check pending queue for result"
      }
      rejectSuccessMessage={"The Registration has been rejected"}
      error={error}
      columnHeaderTextMap={pendingRegistrationsTableHeaders}
    />
  );
};
