import { MouseEventHandler } from "react";
import { ReactComponent as IconNext } from "../../img/svg-icons/pagination-next.svg";
import { ReactComponent as IconSkip } from "../../img/svg-icons/pagination-skip.svg";
import { Props } from "./interfaces";

export const Pagination = ({
  pageNumber,
  pageSize,
  totalCount,
  totalPages,
  paginationNextText = "Next Page",
  paginationPrevText = "Previous Page",
  paginationSkipToEndText = "Skip to last page",
  paginationSkipToStartText = "Skip to first page",
  pageText = "Click to Navigate to page",
  displayingItemsText = "Displaying Items",
  ofText = "of",
  setPageNumberAction,
  numberOfResults = 0,
  visiblePages = 5,
  ariaProps,
}: Props): JSX.Element => {
  if (totalPages === 1) {
    return <></>;
  }

  const { controls } = ariaProps;
  const currentPaginationGroup: number = Math.ceil(pageNumber / visiblePages);
  const lowerRange: number =
    currentPaginationGroup * visiblePages - visiblePages + 1;
  const upperRange: number =
    (currentPaginationGroup * visiblePages > totalPages
      ? totalPages
      : currentPaginationGroup * visiblePages) + 1;
  const isFirstPage: boolean = pageNumber === 1;
  const isLastPage: boolean = pageNumber === totalPages;
  const navItems: Array<any> = [];

  for (let i = lowerRange; i < upperRange; i++) {
    const isActive: boolean = pageNumber === i;
    const classNames: string = isActive
      ? "c-pagination_page-number--active"
      : "";
    const ariaProps = Object.assign(
      {},
      {
        ["aria-current"]: isActive ? "page" : null,
        ["aria-label"]: `${pageText} ${i}`,
      }
    );
    const navItem = (
      <button
        key={i}
        type="button"
        {...(ariaProps as any)}
        className={`c-pagination_button c-pagination_page-number u-mx-1 ${classNames}`}
        onClick={() => setPageNumberAction(i)}
      >
        {i}
      </button>
    );
    navItems.push(navItem);
  }

  const onPrevSkip: MouseEventHandler = (): void => {
    setPageNumberAction(1);
  };

  const onPrev: MouseEventHandler = (): void => {
    setPageNumberAction(pageNumber - 1);
  };

  const onNext: MouseEventHandler = (): void => {
    setPageNumberAction(pageNumber + 1);
  };

  const onNextSkip: MouseEventHandler = (): void => {
    setPageNumberAction(totalPages);
  };

  return (
    <div className="c-pagination u-p-4">
      <nav role="navigation" aria-label="Pagination" aria-controls={controls}>
        <div className="c-pagination_controls">
          {!isFirstPage && (
            <>
              <button
                type="button"
                onClick={onPrevSkip}
                aria-label={paginationSkipToStartText}
                className="c-pagination_button c-pagination_prev-skip"
              >
                <IconSkip className="u-block u-w-4 u-h-4" />
              </button>
              <button
                type="button"
                onClick={onPrev}
                aria-label={paginationPrevText}
                className="c-pagination_button c-pagination_prev"
              >
                <IconNext className="u-block u-w-4 u-h-4 u-mr-4" />
              </button>
            </>
          )}
          <span className="c-pagination_page-number-wrapper">{navItems}</span>
          {!isLastPage && (
            <>
              <button
                type="button"
                onClick={onNext}
                aria-label={paginationNextText}
                className="c-pagination_button c-pagination_next"
              >
                <IconNext className="u-rotate-180 u-block u-w-4 u-h-4 u-ml-4" />
              </button>
              <button
                type="button"
                onClick={onNextSkip}
                aria-label={paginationSkipToEndText}
                className="c-pagination_button c-pagination_next-skip"
              >
                <IconSkip className="u-rotate-180 u-block u-w-4 u-h-4" />
              </button>
            </>
          )}
          <span
            className="u-m-0 u-w-full tablet:u-ml-auto tablet:u-w-auto"
            aria-live="polite"
          >
            {displayingItemsText}: {pageSize * (pageNumber - 1) + 1}-
            {pageSize * (pageNumber - 1) + numberOfResults} {ofText}{" "}
            {totalCount}
          </span>
        </div>
      </nav>
    </div>
  );
};
