import { routeNames, routePaths, Routes } from "../constants/routes";
import { Roles } from "../constants/user";
import { FieldRenderers, FormFieldTypes } from "../types/form";
import { NavItemProps } from "../components/nav-item/interface";
import { fieldIds } from "../constants/fields";

const clientPortalPages: Array<NavItemProps> = [
  {
    title: routeNames[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS],
    href: routePaths[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS],
  },
  {
    title: routeNames[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS],
    href: routePaths[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS],
  },
];

export const generateNavConfig = (userRoles: Roles): Array<NavItemProps> => [
  {
    href: routePaths[Routes.PENDING_RECORDS],
    title: routeNames[Routes.PENDING_RECORDS],
    children: [
      {
        href: routePaths[Routes.PENDING_REGISTRATIONS],
        title: routeNames[Routes.PENDING_REGISTRATIONS],
      },
      {
        href: routePaths[Routes.PENDING_REQUESTS],
        title: routeNames[Routes.PENDING_REQUESTS],
      },
      {
        href: routePaths[Routes.PENDING_EMAIL_CHANGES],
        title: routeNames[Routes.PENDING_EMAIL_CHANGES],
      },
    ],
  },
  {
    href: routePaths[Routes.USER_MANAGEMENT],
    title: routeNames[Routes.USER_MANAGEMENT],
    children: [
      {
        title: routeNames[Routes.PORTAL_USERS],
        href: routePaths[Routes.PORTAL_USERS],
      },
    ],
  },
  {
    title: routeNames[Routes.EMAIL_LOG],
    href: routePaths[Routes.EMAIL_LOG],
  },
  {
    title: routeNames[Routes.SETTINGS],
    href: routePaths[Routes.SETTINGS],
    // here i am using a ternary to destructure either an array containing the items we want to add
    // or an empty array so that when no user roles match nothing is added to the array.
    children: [
      ...(userRoles === Roles.ClientPortalAdmin ? clientPortalPages : []),
    ],
  },
  ...(userRoles === Roles.ClientPortalAdmin
    ? [
        {
          title: routeNames[Routes.REPORTS],
          href: routePaths[Routes.REPORTS],
        },
      ]
    : []),
];
