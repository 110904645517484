import { useContext, useState } from "react";
import { Dialog } from "../../../components/dialog";
import { Pagination } from "../../../components/pagination";
import {
  ComponentActions,
  UserPageRestriction,
  TemplateActionTypes,
} from "../../../types/global";
import { QueryBanner } from "../../../components/queryBanner/queryBanner";
import { userManagementForm } from "../../../formConfig/queryBanner/portalUsers";
import { PortalUsersInnerTemplateDialog } from "../../../components/modal-renderers/portalUsersTemplate";
import { Props } from "./interface";
import { AppContext, AppContextType } from "../../../constants/context";
import { usePageDataQuery } from "../../../hooks/usePageDataQuery";
import { tableHeaders } from "../../../constants/tableHeaders";
import { AdvancedTable } from "../../../components/advancedTable";
import { Success } from "../../../components/success";
import { Portal, Roles } from "../../../constants/user";
import { portalUserStatuses } from "../../../constants";
import {
  getPortalUserNotes,
  reactivatePortalUser,
} from "../../../loaders/pages/portalUsers";
import { Loading } from "../../../components/loading";
import { ConfirmHandler } from "../../../components/confirm";

export const UserManagementPageTemplate = ({
  InnerDialogRenderer = PortalUsersInnerTemplateDialog,
  pageTitleText,
  modalText,
  noResultsText,
  queryBannerText = "Search and filter portal users by email:",
  cancelText,
  saveText,
  tableCaptionText,
  tableId = "UserAdminAppTable",
  tableData: tableDataProps,
  sortableColumns: sortableColumnsProps = [],
  hiddenColumns: hiddenColumnsProps = [],
  pagination: paginationProps,
  apis,
  columnOrder,
  error: errorProp,
  updateApi,
  viewText,
  dataItemKey,
  columnHeaderTextMap = tableHeaders,
}: Props): JSX.Element => {
  const [dialogItem, setDialogItem] = useState<Record<string, any>>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<any>(false);
  const [emailChangeRequestNotes, setEmailChangeRequestNotes] = useState<
    Array<Record<string, any>>
  >([]);

  const [reactivateModalError, setReactivateModalError] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reactivationUserId, setReactivationUserId] = useState<any>();

  const appContext = useContext<AppContextType>(AppContext);
  const { userRoles } = appContext;

  const reactivateUserHandler = async (data: any): Promise<void> => {
    const reactivationSuccessMessage: string = "Success!";

    setReactivateModalError(undefined);
    setIsUpdateSuccess(false);
    setSuccessMessage("");
    setIsLoading(true);

    const { error } = await reactivatePortalUser(data);

    console.log({ error });

    setIsLoading(false);

    if (error) {
      setReactivateModalError(error);
    } else {
      setIsUpdateSuccess(true);
      setSuccessMessage(reactivationSuccessMessage);

      onGet({
        pagination: paginationState,
        sorting: activeSort ? activeSort : undefined,
      });
    }
  };

  // create actions run on accept/cancel when reactivating user
  const confirmDialogueCancelHandler: Function = () =>
    setShouldShowConfirm(false);
  const confirmDialogueAcceptHandler: Function = async () => {
    setShouldShowConfirm(false);
    reactivateUserHandler({ azureUserId: reactivationUserId });
  };

  const {
    pageDataRequestError,
    activeSort,
    paginationState,
    tableDataState,
    onFormSubmit,
    onGet,
    onPageChange,
    onSort,
  } = usePageDataQuery(
    {
      formValues,
      tableDataProps,
      paginationProps,
      initialError: errorProp,
      apis,
      columnOrder,
    },
    { setFormValues }
  );

  const { totalCount, totalPages, pageNumber, pageSize } =
    paginationState ?? {};
  const hasTableData = tableDataState?.length > 0;

  const modalActions: ComponentActions = [
    {
      text: cancelText,
      callback: (): void => {
        handleCloseDialog();
      },
      props: {
        type: "button",
      },
    },
    {
      text: saveText,
      props: {
        type: "submit",
      },
    },
  ];

  const actions: TemplateActionTypes = {
    table: [
      {
        text: (data) => {
          const { portal, status } = data;

          if (
            portal === Portal.ClientPortal &&
            status !== portalUserStatuses.Active &&
            status !== portalUserStatuses.ResubmittedActive
          ) {
            return "View";
          }

          return viewText;
        },
        srText: (data) => data[dataItemKey] ?? "Item",
        callback: async (data: any) => {
          const { azureUserId, status, portal } = data ?? {};
          if (azureUserId) {
            getPortalUserEmailChangeRequestNotes(azureUserId);
          }

          setDialogItem(data);
          setIsDialogOpen(true);

          const shouldEnableReactivation: boolean =
            (status === portalUserStatuses.Removed ||
              status === portalUserStatuses.Rejected ||
              status === portalUserStatuses.ResubmittedRejected ||
              status === portalUserStatuses.ResubmittedRemoved) &&
            portal === Portal.ClientPortal;

          if (shouldEnableReactivation) {
            setReactivationUserId(azureUserId);
          }
        },
      },
    ],
    modal: modalActions,
  };

  const onModalFormSubmit =
    (pageRestrictions: Array<UserPageRestriction>) =>
    (modalFormValues: Record<string, string>) =>
      new Promise(async (resolve) => {
        setIsUpdateSuccess(false);

        const response = await updateApi({
          formValues: modalFormValues,
          pageRestrictions,
          item: dialogItem,
        });

        const { error } = response;

        if (error) {
          return resolve({
            hasSubmitError: true,
            submitError: error,
          });
        } else {
          setIsUpdateSuccess(true);
          onGet({
            formValues: formValues,
            pagination: paginationState,
            sorting: activeSort,
          });
          // resolve to satisfy final form
          return resolve({});
        }
      });

  const handleCloseDialog = (): void => {
    setIsUpdateSuccess(false);
    setIsDialogOpen(false);
    setDialogItem({});
    setReactivationUserId(null);
    setReactivateModalError(null);
    setShouldShowConfirm(false);
  };

  const getPortalUserEmailChangeRequestNotes = async (userId: string) => {
    const response = await getPortalUserNotes({ userId });

    const { error, data: notes } = response;

    if (!error) {
      setEmailChangeRequestNotes(notes);
    }
  };

  const renderDialogContent: () => JSX.Element = () => {
    if (isLoading) {
      return <Loading isActive={isLoading} />;
    } else if (shouldShowConfirm) {
      return (
        <ConfirmHandler
          confirmationText={"Are you sure you want to reactivate this user?"}
          acceptAction={confirmDialogueAcceptHandler}
          cancelAction={confirmDialogueCancelHandler}
        />
      );
    } else if (isUpdateSuccess) {
      return <Success message={successMessage} />;
    }

    return (
      <InnerDialogRenderer
        title={modalText}
        actions={actions.modal}
        data={dialogItem}
        reactivateError={reactivateModalError}
        changeRequestNotes={emailChangeRequestNotes}
        onFormSubmit={onModalFormSubmit}
        onReactivate={() => {
          setShouldShowConfirm(true);
        }}
      />
    );
  };

  return (
    <>
      <div className="u-bg-burnham-green u-text-center u-text-white u-m-0 u-p-2 u-to-viewport-edge-x">
        <h2>{pageTitleText}</h2>
      </div>
      <QueryBanner
        title={queryBannerText}
        formConfig={userManagementForm(userRoles)}
        onFormSubmit={onFormSubmit}
      />
      <div className="u-flex u-flex-col u-justify-center u-my-5 u-w-full u-overflow-x-auto">
        {pageDataRequestError ? (
          <>{pageDataRequestError}</>
        ) : !hasTableData ? (
          <>{noResultsText}</>
        ) : (
          <AdvancedTable
            data={tableDataState}
            caption={tableCaptionText}
            id={tableId}
            sortableColumns={sortableColumnsProps}
            hiddenColumns={hiddenColumnsProps}
            sortFunction={onSort}
            activeSort={activeSort}
            columnHeaderTextMap={columnHeaderTextMap}
            actions={actions.table}
          />
        )}
      </div>
      {hasTableData && (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={totalCount}
          totalPages={totalPages}
          numberOfResults={tableDataState.length}
          setPageNumberAction={onPageChange}
          ariaProps={{
            controls: tableId,
          }}
        />
      )}
      <Dialog
        id={dialogItem?.id}
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        {renderDialogContent()}
      </Dialog>
    </>
  );
};
