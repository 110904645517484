import classNames from "classnames";
import { ActionButton } from "../../actionButton";
import { RichText } from "../../RichText";
import { Props } from "../interface";

export const EmailTemplateInnerDialog = ({
  data,
  actions,
}: Props): JSX.Element => {
  const { emailBody, emailType, dateTimeSent, emailAddress, subject } =
    data ?? {};

  const recipientText: string = "To";
  const dateSentText: string = "Date";
  const subjectText: string = "Subject";

  const generatedClasses: Record<string, string> = {
    buttonWrapper: classNames("u-flex u-justify-end u-mb-6"),
    button: classNames(
      "u-bg-teal-green u-text-white u-border-none u-p-0 u-rounded-xl u-my-2 u-flex u-items-center"
    ),
    svgButton: classNames(
      "u-bg-transparent u-border-none u-p-0 u-m-0 u-text-fiord-blue u-py-2 u-flex u-items-center"
    ),
    item: classNames("u-flex u-flex-col u-items-left u-px-1"),
    dlItem: classNames("u-flex u-gap-2 u-flex-wrap u-my-2"),
    itemTitle: classNames("u-font-bold"),
    itemText: classNames("u-p-0 u-m-0"),
    buttons: classNames("u-flex u-mx-2 u-gap-2 u-flex-wrap u-mt-8", {
      ["u-justify-between"]: actions ? actions?.length > 1 : false,
      ["u-justify-center"]: actions ? actions?.length <= 1 : false,
    }),
  };
  return (
    <div className="u-p-3">
      <dl className={generatedClasses.item}>
        <div className={generatedClasses.dlItem}>
          <dt className={generatedClasses.itemTitle}>{recipientText}:</dt>
          <dd className={generatedClasses.itemText}>{emailAddress}</dd>
        </div>
        <div className={generatedClasses.dlItem}>
          <dt className={generatedClasses.itemTitle}>{dateSentText}:</dt>
          <dd className={generatedClasses.itemText}>
            {new Date(dateTimeSent).toLocaleDateString()}
          </dd>
        </div>
        <div className={generatedClasses.dlItem}>
          <dt className={generatedClasses.itemTitle}>{subjectText}:</dt>
          <dd className={generatedClasses.itemText}>{subject}</dd>
        </div>
      </dl>
      <hr />
      <div>
        <RichText bodyHtml={emailBody} />
      </div>
      <div className={generatedClasses.buttons}>
        {actions?.map(({ text, callback }, index) => (
          <ActionButton
            className="u-rounded-none u-w-fit"
            callback={callback}
            key={index}
          >
            <>{text}</>
          </ActionButton>
        ))}
      </div>
    </div>
  );
};
