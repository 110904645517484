import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import {
  dataKeys,
  pendingRequestsTableHeaders,
} from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import {
  resubmitPendingRequest,
  rejectPendingRequest,
} from "../../../loaders/pages/pendingRequests";
import { PendingPagesTemplate } from "../../templates/pendingPageTemplate";

export const PendingRequests = () => {
  let { data, pagination, error }: any = useLoaderData();

  const { hiddenColumns, order, sortable, hiddenFields } =
    columnConfig.pendingRequestColumnConfig;
  data = orderColumns(data, order);

  return (
    <PendingPagesTemplate
      tableData={data}
      columnOrder={order}
      hiddenColumns={hiddenColumns}
      sortableColumns={sortable}
      hiddenModalFields={hiddenFields}
      pagination={pagination}
      pageTitleText="Pending Requests"
      modalText="Pending Request"
      noResultsText="There are no pending requests whose email address contains your search term. Please search again"
      resubmitText="Resubmit request"
      rejectText="Reject request"
      tableCaptionText="Pending requests"
      queryBannerText="Search requests or filter by:"
      viewText={"View"}
      confirmHandlerText="Are you sure you would like to reject this request?"
      dataItemKey={dataKeys.email}
      apis={{
        get: routeApis[Routes.PENDING_REQUESTS].getPendingItems,
        reject: routeApis[Routes.PENDING_REQUESTS].rejectPendingItem,
        accept: routeApis[Routes.PENDING_REQUESTS].resubmitPendingItem,
      }}
      resubmitHandler={resubmitPendingRequest}
      rejectHandler={rejectPendingRequest}
      rejectSuccessMessage={"The access request has been rejected"}
      resubmitSuccessMessage={
        "Request resubmitted - please check pending queue for result"
      }
      columnHeaderTextMap={pendingRequestsTableHeaders}
      error={error}
    />
  );
};
