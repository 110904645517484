import { SortButton } from "../../components/sortButton";
import { Cell, Row } from "../../components/table/interface";
import { tableHeaders as tableHeaderMap } from "../../constants/tableHeaders";
import { GenerateButtonsRenderer } from "../action-button-generators";
import { tableCellFormatters } from "../formatters";
import { ColumnHeaderProps, RowHeaderProps } from "./interface";

export const generateColumnHeaders = ({
  data = {},
  sortableColumns,
  hiddenColumns,
  SortComponent = SortButton,
  sortFn,
  activeSort,
  columnHeaderTextMap = tableHeaderMap,
}: ColumnHeaderProps): Array<Cell> => {
  // to remove column that are supposed to be hidden
  const filtered = Object.keys(data).filter(
    (item) => !hiddenColumns?.includes(item)
  );

  const columns: Array<Cell> = filtered.map((columnId: string): Cell => {
    const column: Cell = {
      text: columnHeaderTextMap[columnId] ?? columnId,
    };

    if (sortableColumns?.includes(columnId)) {
      column.Renderer = SortComponent;
      column.props = {
        callback: () => sortFn?.(columnId),
        activeSort: activeSort?.column === columnId ? activeSort : null,
      };
    }

    return column;
  });

  return columns;
};

export const generateRows = ({
  data,
  actions,
  hiddenColumns,
  formatters = tableCellFormatters,
}: RowHeaderProps): Array<Row> => {
  const dataCopy = Object.assign([], data);

  const rows: Array<Row> = dataCopy.map((row): Row => {
    let children: Array<Cell> = [];

    Object.keys(row).forEach((key) => {
      let text: string = row[key];

      if (formatters?.[key]) {
        text = formatters[key](row[key]);
      }

      //remove cells whose columns are supposed to be hidden
      if (!hiddenColumns?.includes(key)) {
        children.push({
          text: text,
        });
      }
    });

    if (actions) {
      children.push({
        Renderer: GenerateButtonsRenderer({ actions, data: row }),
      });
    }

    return { children };
  });

  return rows;
};
