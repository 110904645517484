import React, { MouseEvent, useRef, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { mediaQueries } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { NavBanner } from "../nav-banner";
import { Props } from "./interface";
import { ReactComponent as DropdownIcon } from "../../img/svg-icons/dropdown.svg";
import classNames from "classnames";

export const NavItem = ({
  title,
  href,
  children,
  closeNav,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isTablet: boolean = useMediaQuery({ query: mediaQueries.TABLET });

  const generatedClasses: Record<string, string> = {
    item: classNames("c-main-nav_item"),
    svg: classNames("u-w-7 u-h-7"),
    link: classNames("c-main-nav_link"),
    linkTitle: classNames("c-main-nav_link-title"),
    button: classNames("c-main-nav_link u-border-none u-border-0 u-m-0"),
    banner: classNames({
      "u-sr-only": !isTablet && !isOpen,
    }),
  };

  const handleClick = (e: MouseEvent): void => {
    setIsOpen(!isOpen);
  };

  const renderHeader = (): JSX.Element => (
    <>
      <span className={generatedClasses.linkTitle}>{title}</span>
      {children?.length && <DropdownIcon className={generatedClasses.svg} />}
    </>
  );

  const handleCloseNav = (): void => {
    setIsOpen(false);
    closeNav?.();
  };

  return (
    <li className={generatedClasses.item}>
      {isTablet || (!isTablet && !children?.length) ? (
        <Link
          className={generatedClasses.link}
          to={href}
          onClick={handleCloseNav}
        >
          {renderHeader()}
        </Link>
      ) : (
        <button className={generatedClasses.button} onClick={handleClick}>
          {renderHeader()}
        </button>
      )}
      {children?.length && (
        <NavBanner
          className={generatedClasses.banner}
          parent={{ href, title }}
          children={children}
          callback={handleCloseNav}
        />
      )}
    </li>
  );
};
