import classNames from "classnames";

export interface LoadingProps {
  isActive: boolean;
  titleText?: string;
  bodyText?: string;
  className?: string;
}

export const Loading = ({
  isActive,
  titleText = "Loading admin application",
  bodyText = "please wait...",
  className,
}: LoadingProps): JSX.Element => {
  const classes: string = classNames(
    className,
    "u-flex u-items-center u-justify-center",
    {
      ["u-hidden"]: !isActive,
    }
  );

  return (
    <div className={classes}>
      <div className="c-loading u-flex u-items-center u-justify-center u-flex-col u-font-bold">
        <p className="u-text-2xl">{titleText}</p>
        <div className="c-spinner u-my-8 u-mx-auto">
          <div className="c-spinner_segment"></div>
          <div className="c-spinner_circle"></div>
        </div>
        <p>{bodyText}</p>
      </div>
    </div>
  );
};
