import { ReactComponent as IconTick } from "../../img/svg-icons/tick.svg";

interface Props {
  message?: string;
}

export const Success = ({ message = "Success!" }: Props) => {
  return (
    <div className="u-flex u-flex-col u-items-center u-justify-center">
      <IconTick
        fill="currentColor"
        className="u-w-20 u-h-20 u-my-4 u-text-teal-green"
      />
      <p>{message}</p>
    </div>
  );
};
