import { messageConstants } from "../../constants";
import { isDefined } from "../../helpers/utils";
import { FieldValidatorConfig } from "../validator";

export interface EmailValidator extends FieldValidatorConfig {}

export const wordCountValidator = (
  value: string,
  formValues: Record<string, string>,
  validatorConfig: any
) => {
  const { message } = validatorConfig;
  const maxWordCount: number = 450;

  if (!isDefined(message)) {
    throw new Error(messageConstants.validatorMisconfigured);
  }

  if (value) {
    const count: number = value.split(" ").length;

    if (count > maxWordCount) {
      return message;
    }

    return undefined;
  }

  return undefined;
};
